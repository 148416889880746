<template>
    <div class="SolidityDetection">
        <hello 
        :inputContent="inputContent"
        :title="title"
        :title02="title02"
        :systemPrompt="systemPrompt"
        :name_model="name_model"
        @input-change="handleInputChange"
        ></hello>
    </div>
</template>
<script>
import hello from "@/components/tool/helloWorld.vue";
export default {
    components:{
            hello
        },
data() {
    return {
        input: '',
        title: "Solidity合约检测🆕",
        name_model: "Solidity合约检测",
        title02:"输入需要优化的Solidity合约，可以帮你检查bug或者告诉你智能合约还可以优化的地方。",
        inputContent: "示例：pragma solidity 0.4.24;contract hello {    function mutiply(uint a) returns (uint result) {return a*3;   }}",
        systemPrompt:"solidity_assistant"  //对话模组
    };
},
methods: {
    handleInputChange(val) {
    this.input = val;
    alert(this.input)
    }
}


};
</script>

<style scoped>
</style>