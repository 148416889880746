<template>
  <div class="Admin">
    <div class="sidebar">
      <el-menu default-active="1-1" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="isCollapse">
        <el-menu-item index="1-1" @click="showUserListModule">
          <i class="el-icon-user"></i>
          <span slot="title">用户中心</span>
        </el-menu-item>
        <el-menu-item index="1-2" @click="showKeyModule">
          <i class="el-icon-key"></i>
          <span slot="title">key管理</span>
        </el-menu-item>
      </el-menu>
    </div>
    
    <div class="main">
      <userListModule v-if="showUserList"></userListModule>
      <keyModule v-if="showKey"></keyModule>
    </div>
  </div>
</template>

<script>
import keyModule from '@/components/model/keyModule.vue';
import userListModule from '@/components/model/userListModule.vue';

export default {
  components: {
    keyModule,
    userListModule
  },
  data() {
    return {
      isCollapse: true,
      showUserList: true,
      showKey: false
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    showUserListModule() {
      this.showUserList = true;
      this.showKey = false;
    },
    showKeyModule() {
      this.showUserList = false;
      this.showKey = true;
    }
  }
}
</script>

<style>
@media (max-width: 768px) {
  .Admin {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    height: auto;
    position: static;
    margin-bottom: 20px;
  }

  .main {
    margin-left: 0;
  }
}
.Admin {
  display: flex;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 70px;
  background-color: #f2f2f2;
}

.main {
  flex: 1;
  padding: 20px;
  margin-left: 70px; /* 为侧边栏留出空间 */
}
</style>