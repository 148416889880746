<template>
    <div class="ReadingAnalysis">
        <hello 
        :inputContent="inputContent"
        :title="title"
        :title02="title02"
        :systemPrompt="systemPrompt"
        :name_model="name_model"
        @input-change="handleInputChange"
        ></hello>
    </div>
</template>
<script>
import hello from "@/components/tool/helloWorld.vue";
export default {
    components:{
            hello
        },
data() {
    return {
        input: '',
        title: "阅读小助手",
        name_model: "阅读分析",
        title02:"输入难懂段落，让我们一起解读。",
        inputContent: "示例：哪有什么一帆风顺的生活,不过是来自内心积极的推动与柔软的转换,去经历那些寸步难行或举重若轻",
        systemPrompt:"ReadingAnalysis"  //对话模组
    };
},
methods: {
    handleInputChange(val) {
    this.input = val;
    alert(this.input)
    }
}


};
</script>

<style scoped>
</style>