<template>
  <div class="app" style="font-size: 15px">
    <div class="cent" style="width: 100%">
      <el-drawer title="会话列表" :visible.sync="drawer" :direction="direction" :with-header="false">
        <div style="padding: 50px 30px">
          <button @click="add_session()" class="newsession" style="margin: 0, 10px; min-width: 150px">
            <i class="el-icon-circle-plus-outline" style="font-size: 16px">&nbsp; 新建会话</i>
          </button>
          <div style="overflow-y: auto; height: 60%; margin-top: 20px">
            <div v-for="(list_id, index) in user_session_id_list" :key="index" style="margin: 5px; margin-bottom: 10px">
              <div>
                <div class="list_id" @click="
                  change(list_id), activeFun(list_id, index), (drawer = false)
                  " :class="{ activeCss: activeVar == list_id }">
                  <span>
                    <span style="display: inline-block; width: 100px">{{
                      list_id
                    }}</span>

                    &nbsp; &nbsp;
                    <i style="color: red" @click="deleteSession" class="el-icon-delete"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <a @click="UserInformation"><button style="border: 1px rgb(228, 197, 197) solid; border-radius: 3px"
            class="account_info">
            <span class="el-icon-user" style="font-size: 16px"></span> 用户中心
            <!-- &nbsp; {{ email }} -->
          </button></a>
        <br />
        <span style="font-size: 12px; margin-left: 50px">版本@v2.8.1 | 光速迭代中</span>
      </el-drawer>
      <div id="app" style="
          margin-top: 60px;
          height: 100%;
          padding-bottom: 120px;
          min-height: 80vh;
          padding-left: 0px;
          padding-right: 0px;
        ">
        <!-- <h1>智能聊天室</h1> -->
        <div class="header">
          <span style="font-size: 13px">
            <span class="el-icon-s-unfold" @click="drawer = true" style="font-size: 30px"></span>
            <br />
            <div style="display: flex; justify-content: space-between">
              <div style="width: 200px">
                <span style="
                    color: rgb(189, 144, 232);
                    font-size: 20px;
                    font-weight: 600;
                    width: 100%;
                  ">{{ session_id_now }}</span>
              </div>
              <el-popover placement="bottom" width="200" trigger="click">
                <div>
                  <span class="el-icon-s-grid" @click="getback()" title="返回列表"></span>
                  <span class="el-icon-edit" @click="ModifySession()" title="重命名对话"
                    @keyup.enter="change_session_id"></span>
                  <span class="el-icon-bell" title="通知" @click="dialogVisible = true"
                    style="color: rgb(65, 78, 197)"></span>
                  <span class="el-icon-refresh" title="刷新" @click="refresh"></span>
                  <span class="el-icon-picture-outline" title="导出图片" @click="exportPic('chat')"></span>
                  <el-dialog title="提示" :visible.sync="dialogVisible" :append-to-body="true" width="80%">
                    <p>全新UI更好的体验</p>
                    <p style="color: rgb(90, 231, 231)">
                      为了用户隐私，不会保存用户聊天记录，请自行保存！
                    </p>
                    <p style="color: rgb(209, 156, 9)">
                      邀请好友注册填写你的UID即刻获得4万-10万token，被邀请人获得3万-8万token
                    </p>
                    <p style="color: rgb(252, 152, 3)">
                      进QQ交流群免费领10万token
                    </p>
                    <p style="color: rgb(19, 190, 13)">QQ群：602354227</p>
                    <p style="color: rgb(55, 176, 247)">
                      更新gpt3.5 16k 上下文关联和回答内容比上代增强4倍
                    </p>
                    <p style="color: rgb(247, 35, 7)">
                      联网功能已经添加，可以获取近日或更精准的内容
                    </p>
                    <p style="color: rgb(55, 176, 247)">
                      更新签到功能，每日都可以签到免费获取3千-3万token！
                    </p>
                    <span slot="footer" class="dialog-footer">
                      <el-button type="primary" @click="dialogVisible = false" style="font-size: 14px; padding: 5px">确
                        定</el-button>
                    </span>
                  </el-dialog>
                </div>
                <!-- <el-button ></el-button> -->
                <i class="el-icon-more" style="font-size: 30px; margin-right: 20px" slot="reference"></i>
              </el-popover>
            </div>
          </span>
        </div>
        <!-- <CherryMD :height="800" v-model="mdContent" /> -->

       <div style="
            height: 75%;
            overflow-y: scroll;
            margin-top: 5px;
            font-size: 14px;
          " id="chat">
          <div v-for="(message, index) in messages" :key="index" style="width: 100%">
            <div v-if="message.role === 'system'">
              <div style="text-align: left">
                <div class="system" style="display: inline-block">
                  {{ message.content }}
                </div>
              </div>
            </div>
            <div v-else-if="message.role === 'user'">
              <div style="text-align: right">
                <div class="user" style="display: inline-block">
                  <div style="
                      max-width: 100%;
                      overflow: auto;
                      display: inline-block;
                    ">
                    {{ message.content }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="md">
                <div class="system" style="display: inline-block; position: relative">
                  <div class="copy" @click="copy(message.content)">
                    <i class="el-icon-copy-document" style="font-size: 20px; padding: 5px"></i>
                  </div>
                  <v-md-preview :text="message.content" style="display: inline-block"></v-md-preview>
                </div>
              </div>
            </div>
          </div>
        </div> 
        <div id="under">
          <el-popover placement="top" trigger="click">
            <el-switch v-model="continue_chat" active-text="连续对话" inactive-text="不连续对话" active-value="true"
              inactive-value="false" @change="cg_continue_chat">
            </el-switch>
            <i class="el-icon-chat-line-round" slot="reference" style="font-size: 30px; color: rgb(49, 182, 16)"></i>

            <!-- <el-button slot="reference">click 激活</el-button> -->
          </el-popover>

          <i class="el-icon-arrow-down" style="font-size: 30px; margin: 0 10px" @click="move_buttom"></i>

          <el-popover placement="top" trigger="click">
            <!-- <el-switch v-model="gpt_model" active-text="GPT4 + 联网" inactive-text="光速 GPT3.5" active-value="true"
              inactive-value="false" @change="cg_gpt_model">
            </el-switch> -->
            <el-select v-model="gpt_model" placeholder="请选择" @change="cg_gpt_model">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled">
              </el-option>
            </el-select>
            <i class="el-icon-trophy-1" slot="reference" style="font-size: 30px; color: rgb(255, 204, 35)"></i>
          </el-popover>
          <el-popover placement="top" trigger="click">
            <span>
              免费公益站, 请作者喝杯咖啡,让他不要破产
              <img src="https://nb.sb/qq.jpg" alt="" style="width: 10%; display: inline-block" /></span>
            <img src="../static/wx.jpg" alt="" style="width: 30%; display: inline-block" />
            <img src="../static/zfb.jpg" alt="" style="width: 30%; display: inline-block" />
            <i class="el-icon-star-off" slot="reference" style="font-size: 30px; color: rgb(255, 204, 35)"></i>
          </el-popover>
          <!-- <el-progress :percentage="number" :color="customColorMethod" style="width:300px"></el-progress> -->
          <div class="input_ii">
            <textarea v-show="button_loding01" type="text" v-model="messageInput" @input="resizeTextarea" rows="1"
              @keydown="handleKeyDown" style="
                overflow-y: auto;
                max-height: 30vh;
                height: auto;
                width: 100%;
              "></textarea>
            <input v-show="button_loding" placeholder="正在回复中，请稍后~" v-model="messageInput" :disabled="true"
              style="overflow-y: auto; max-height: 30vh; width: 100%" />
            <div @click="sendMessage" class="send" v-show="button_loding01">
              <span class="el-icon-position" style="font-size: 30px"></span>
            </div>
            <div class="send" v-show="button_loding">
              <span class="el-icon-loading" style="font-size: 30px; width: 30px"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import CherryMD from "@/components/model/CherryMD";

import { GPTAPI } from "@/config";
import html2Canvas from "html2canvas";
// md
import MarkdownIt from "markdown-it";
import hljs from "highlight.js";
import "highlight.js/styles/default.css";
import Clipboard from "clipboard";
var { expressjwt: jwt } = require("express-jwt");
const axios = require("axios");
export default {
  // components: {
  //   CherryMD,
  // },
  el: ".cent",
  // components: {
  //     'vue-markdown': window["vue-markdown2"]
  // },
  data() {
    return {
      mdContent: "## hi",
      options: [{
          value: 'gpt-3.5-turbo-16k',
          label: 'GPT-3.5-turbo-16k'
        }, {
          value: 'gpt38',
          label: 'GPT-3.5+联网',
          // disabled: true
        }, {
          value: 'gpt-4',
          label: 'GPT-4.0'
        }],
        // value: ''
      gpt_model: "GPT-3.5-turbo-16k",
      continue_chat: true,
      clipboard: "",
      GPTAPI: GPTAPI,
      dialogVisible: false,
      activeVar: "",
      direction: "ltr",
      drawer: false,
      text: "",
      renderedContent: "",
      //   visible: false,
      isAuthenticated: false,
      messages: [],
      messageInput: "",
      show: true,
      user_session_id: "新的聊天", // 初始化为 null
      user_session_id_list: [],
      chat_count: 0,
      balance: "0",
      number: "0",
      remove_number: "",
      email: "",
      button_loding: false,
      button_loding01: true,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.clipboard = new Clipboard(".copy-btn");
      // 复制成功失败的提示
      this.clipboard.on("success", () => {
        this.$message.success("复制成功");
      });
      this.clipboard.on("error", () => {
        this.$message.error("复制成功失败");
      });
    });
  },
  destroyed() {
    this.clipboard.destroy();
  },

  methods: {
    // 导出图片
    exportPic(DivID) {
      html2Canvas(document.querySelector("#" + DivID)).then((canvas) => {
        let dataURL = canvas.toDataURL("image/png");
        if (dataURL !== "") {
          var a = document.createElement("a");
          a.download = "聊天会话";
          a.href = dataURL;
          a.click();
        }
      });
    },
    cg_continue_chat() {
      sessionStorage.setItem("continue_chat", this.continue_chat);
    },
    cg_gpt_model() {
      sessionStorage.setItem("gpt_model", this.gpt_model);
    },
    handleKeyDown(event) {
      if (event.ctrlKey && event.keyCode === 13) {
        this.sendMessage();
      }
    },
    add_session() {
      this.$prompt("请输入新建会话名(如: 英语问答会话)", "新增会话", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        center: true,
        inputPlaceholder: "英语问答会话",
        inputValue: "新建会话",
      })
        .then(({ value }) => {
          if (value === null) {
            this.$message({
              showClose: true,
              message: "请输入有效的字",
              type: "error",
            });
            return "";
          }
          this.user_session_id_list.push(value);
          this.user_session_id = value;
          this.messages = [];
          this.messages.push({
            role: "assistant",
            content: "我是全能AI选手！请说出你的问题！",
          });
          this.change(this.user_session_id);
        })
        .catch(() => {
          this.$message({
            type: "info",
            showClose: true,
            message: "取消输入",
          });
        });
    },
    ModifySession() {
      this.$prompt("请输入会话备注(如: 电脑配置问答)", "修改会话", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        center: true,
        inputPlaceholder: this.session_id_now,
        inputValue: this.session_id_now,
      })
        .then(({ value }) => {
          this.session_id_now = value;
          const token = localStorage.getItem("token");
          const config = {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: ` ${token}`,
              "X-Requested-With": "XMLHttpRequest",
            },
          };
          const data = {
            old_id: this.user_session_id,
            new_id: value,
          };
          axios
            .post(GPTAPI + "/now_session_id", data, config)
            .then(() => {
              this.$message({
                type: "success",
                message: "修改成功，现在的会话备注为：" + value,
              });
              this.show = !this.show;
              this.change(value);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            showClose: true,
            message: "取消输入",
          });
        });
    },
    copy(content, message) {
      var aux = document.createElement("input");
      aux.setAttribute("value", content);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      if (message == null) {
        this.$notify({
          title: "copy",
          showClose: true,
          message: "复制成功~",
          type: "success",
        });
      } else {
        this.$notify({
          title: "copy",
          showClose: true,
          message: "没有内容~",
          type: "error",
        });
      }
    },
    getback() {
      this.$router.push("/IndexShow");
      location.reload();
    },

    activeFun(list_id) {
      // item 为被选中的元素体
      this.activeVar = list_id;
      sessionStorage.setItem("activeVar", list_id);
    },
    handleCopyCodeSuccess(code) {
      console.log(code);
    },
    loding() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      setTimeout(() => {
        loading.close();
      }, 500);
    },

    markdownToHtml(markdownText) {
      const md = new MarkdownIt({
        html: true,
        linkify: true,
        typographer: true,
        highlight: function (str, lang) {
          // 当前时间加随机数生成唯一的id标识
          const codeIndex =
            parseInt(Date.now()) + Math.floor(Math.random() * 10000000);
          // 复制功能主要使用的是 clipboard.js
          let html = `<button class="copy-btn" type="button" data-clipboard-action="copy" data-clipboard-target="#copy${codeIndex}">复制</button>`;
          const linesLength = str.split(/\n/).length - 1;
          // 生成行号
          let linesNum = '<span aria-hidden="true" class="line-numbers-rows">';
          for (let index = 0; index < linesLength; index++) {
            linesNum = linesNum + "<span></span>";
          }
          linesNum += "</span>";
          if (lang && hljs.getLanguage(lang)) {
            try {
              // highlight.js 高亮代码
              const preCode = hljs.highlight(lang, str, true).value;
              html = html + preCode;
              if (linesLength) {
                html += '<b class="name">' + lang + "</b>";
              }
              // 将代码包裹在 textarea 中
              return `<pre class="hljs"><code>${html}</code>${linesNum}</pre><textarea style="position: absolute;top: -9999px;left: -9999px;z-index: -9999;" id="copy${codeIndex}">${str.replace(
                /<\/textarea>/g,
                "&lt;/textarea>"
              )}</textarea>`;
            } catch (error) {
              console.log(error);
            }
          }
          const preCode = md.utils.escapeHtml(str);
          html = html + preCode;
          // 将代码包裹在 textarea 中
          return `<pre class="hljs"><code>${html}</code>${linesNum}</pre><textarea style="position: absolute;top: -9999px;left: -9999px;z-index: -9999;" id="copy${codeIndex}">${str.replace(
            /<\/textarea>/g,
            "&lt;/textarea>"
          )}</textarea>`;
        },
      });
      const result = md.render(markdownText);
      return result;
    },
    // markdownToHtml(markdownText) {
    //     const md2 = new markdownit();
    //     return md2.render(markdownText);
    // },
    UserInformation() {
      this.$router.push("/UserInformation");
      window.location.reload();
    },
    // 刷新
    refresh() {
      // 刷新当前网页
      location.reload();
    },
    // // 切换会话
    change(list_id) {
      this.user_session_id = list_id;
      this.get_session();
      sessionStorage.setItem("user_session_id", list_id);
    },
    // 获取会话列表
    get_user_session_id_list() {
      const token = localStorage.getItem("token");
      var i;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: ` ${token}`,
          "X-Requested-With": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*",
        },
      };
      var data2 = {};
      axios
        .post(GPTAPI + "/get_user_session_list", data2, config)
        .then((response) => {
          for (i in response.data) {
            this.user_session_id_list.push(response.data[i]);
          }
          // this.user_session_id = this.user_session_id_list[0]
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // 输入框`
    resizeTextarea_init() {
      const textarea = this.$el.querySelector("textarea");
      textarea.style.height = "35px";
      // textarea.style.height = textarea.scrollHeight + 'px';
    },
    resizeTextarea() {
      const textarea = this.$el.querySelector("textarea");
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },
    no_Input() {
      this.$message.error("未输入内容~");
    },
    move_buttom() {
      window.scrollTo(0, document.body.scrollHeight);
    },
    async sendMessage() {
      this.button_loding = !this.button_loding;
      this.button_loding01 = !this.button_loding01;
      const h = this.$createElement;
      if (this.messageInput.trim() === "") {
        const errorMessage = document.createElement("div");
        this.no_Input();
        document.body.appendChild(errorMessage);
        this.button_loding = !this.button_loding;
        this.button_loding01 = !this.button_loding01;
        return false;
      }
      if (parseInt(this.number) < 100 && parseInt(this.number) >= 0) {
        this.button_loding = !this.button_loding;
        this.button_loding01 = !this.button_loding01;
        this.$notify({
          title: "提示：",
          message: h(
            "i",
            { style: "color: teal" },
            "您的剩余token小于100，但请求可能大于100个token，为了不影响您的正常体验，请进行兑换token后再进行提问，加Q群602354227免费领取10万token"
          ),
        });
        return "";
      } else if (parseInt(this.number) < 0) {
        this.button_loding = !this.button_loding;
        this.button_loding01 = !this.button_loding01;
        this.$notify({
          title: "提示：",
          message: h(
            "i",
            { style: "color: teal" },
            "您的剩余token小于0，为了不影响您的正常体验，请进行兑换token后再进行提问，加Q群602354227免费领取10万token"
          ),
        });
        return "";
      } else {
        this.$notify({
          title: "成功",
          showClose: true,
          message: "发送成功，回复正在书写中~",
          type: "success",
        });
        // 滚动到底部
        this.move_buttom();
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: ` ${token}`,
            "X-Requested-With": "XMLHttpRequest",
          },
        };
        this.messages.push({ role: "user", content: this.messageInput });
        const data = {
          user_session_id: this.user_session_id,
          mess: this.messageInput,
          system_mold: "chatgpt",
          //  设置是否连续对话
          continue: this.continue_chat,
          stream_mes: true,
          gpt_model: this.gpt_model,
        };
        this.messageInput = "";
        this.resizeTextarea_init();
        const response = await fetch(GPTAPI + "/testgpt", {
          method: "POST",
          body: JSON.stringify(data),
          headers: config.headers,
        });
        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let result;
        this.messages.push({ role: "assistant", content: "" });
        do {
          result = await reader.read();
          const chunk = decoder.decode(result.value || new Uint8Array(), {
            stream: !result.done,
          });
          if (chunk !== "assistant") {
            const newContent =
              this.messages[this.messages.length - 1].content + chunk;
            this.messages[this.messages.length - 1].content = newContent;
          }
        } while (!result.done);
      }
      this.remove_number_fun();
      this.button_loding = !this.button_loding;
      this.button_loding01 = !this.button_loding01;
    },
    remove_number_fun() {
      // 减去剩余number字数
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: ` ${token}`,
          "X-Requested-With": "XMLHttpRequest",
        },
      };
      const data2 = {
        user_session_id: this.user_session_id,
        continue: this.continue_chat,
        modul: this.gpt_model
      };
      axios
        .post(GPTAPI + "/remove_number", data2, config)
        .then((response) => {
          // console.log(response.data)
          this.number = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 获取会话记录
    get_session() {
      this.loding();
      this.session_id_now = this.user_session_id; //初始化id
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: ` ${token}`,
          "X-Requested-With": "XMLHttpRequest",
        },
      };
      const data = {
        user_session_id: this.user_session_id,
      };
      axios
        .post(GPTAPI + "/get_user_session", data, config)
        .then((response) => {
          console.log("获取会话：" + response.data);
          this.messages = [];
          // this.messages.push({ role: 'system', content: "请问输入你的solidity代码我将为您查询合约中存在的问题~" });
          this.messages.push({
            role: "system",
            content: "hi~ 请问你有什么问题？我是全能王！",
          });
          for (let ii in response.data) {
            const index = parseInt(ii) + 1;
            // if (index < response.data.length && response.data[index]["content"] == response.data.slice(-1)) {
            // }
            if (index < response.data.length) {
              // 获取会话信息
              // console.log(response.data[index]["content"]);
              if (response.data[index]["role"] == "user") {
                this.messages.push({
                  role: "user",
                  content: response.data[index]["content"],
                });
              }
              if (response.data[index]["role"] == "assistant") {
                this.messages.push({
                  role: "assistant",
                  content: response.data[index]["content"],
                });
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 删除会话
    deleteSession() {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: ` ${token}`,
          "X-Requested-With": "XMLHttpRequest",
        },
      };
      const data = {
        user_session_id: this.user_session_id,
      };
      axios
        .post(GPTAPI + "/delete_session", data, config)
        .then((response) => {
          this.$message({
            showClose: true,
            message: response.data,
            type: "success",
          });
          this.refresh;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 添加 token 验证逻辑
    verifyToken(token, secret) {
      // 解码 token，验证是否过期以及签名是否正确
      const decoded = jwt.verify(token, secret);
      // 返回 token 包含的信息
      return decoded;
    },
    // 提示切换了gpt4
    setGpt4() {
      this.$message({
            showClose: true,
            message: "你已经切换至gpt-4 , 默认为您开启不连续对 , 以防token消耗量过多",
            type: "success",
          });
      this.continue_chat = false
    }
  },
  // eslint-disable-next-line no-dupe-keys
  mounted() {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = this.verifyToken(token, "secret_key");
      console.log(decoded);
      if (decoded) {
        this.isAuthenticated = true;
        this.username = decoded.username;
      } else {
        this.$notify.error({
          title: "错误",
          message: "未登录，正在为您跳转至登录界面~",
        });
        // token 无效，重定向至登录页面
        setTimeout(() => {
          this.$router.push("/StartLogin");
          location.reload();
        }, 2000);
      }
    } else {
      this.$router.push("/StartLogin");

      this.$notify.error({
        title: "错误",
        message: "未登录，正在为您跳转至登录界面~",
      });
      location.reload(); 
    }
  },
  watch: {
    gpt_model(newVal) {
      if (newVal === 'gpt-4') {
        this.setGpt4();
      }
    }
  },
  created() {
    const loading = this.$loading({
      lock: true,
      text: "正在飞速加载中~",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
    });
    if (sessionStorage.getItem("gpt_model") === null) {
      sessionStorage.setItem("gpt_model", this.gpt_model);
    } else {
      this.gpt_model = sessionStorage.getItem("gpt_model");
    }
    if (sessionStorage.getItem("continue_chat") === null) {
      sessionStorage.setItem("continue_chat", this.continue_chat);
    } else {
      this.continue_chat = sessionStorage.getItem("continue_chat");
    }

    this.messages.push({
      role: "system",
      content: "hi~ 请问你有什么问题？我是全能王！",
    });
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: ` ${token}`,
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
      },
    };
    // 初始化会话列表
    var i;
    var data2 = {};
    axios
      .post(GPTAPI + "/get_user_session_list", data2, config)
      .then((response) => {
        for (i in response.data) {
          if (response.data[i] != null) {
            this.user_session_id_list.push(response.data[i]);
          }
        }
        // 初始化 id

        // 会话选择存储
        this.session_id_now = sessionStorage.getItem("activeVar") || "新的聊天";
        this.user_session_id =
          sessionStorage.getItem("activeVar") || "新的聊天";
        this.activeVar = sessionStorage.getItem("activeVar") || "新的聊天";

        const data = {
          // user_session_id: this.user_session_id_list[0], // 使用 user_session_id
          user_session_id: this.session_id_now,
        };
        // 初始化会话界面
        axios
          .post(GPTAPI + "/get_user_session", data, config)
          .then((response) => {
            for (let ii in response.data) {
              const index = parseInt(ii) + 1;
              if (index < response.data.length) {
                // 获取会话信息
                if (response.data[index]["role"] == "user") {
                  this.messages.push({
                    role: "user",
                    content: response.data[index]["content"],
                  });
                }
                if (response.data[index]["role"] == "assistant") {
                  this.messages.push({
                    role: "assistant",
                    content: response.data[index]["content"],
                  });
                }
              }
            }
            loading.close();
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
    var data;
    // 查看用户信息
    axios
      .post(GPTAPI + "/get_user_infomation", data, config)
      .then((response) => {
        this.balance = response.data.balance;
        this.email = response.data.email;
        this.number = response.data.number;
      })
      .catch((error) => {
        console.log(error);
      });
    loading.close();
  },

};
</script>
<style scoped >
.el-icon-arrow-down {
  border: #c5c5c7 1px solid;
  border-radius: 5px;
}

.el-icon-s-grid,
.el-icon-refresh,
.el-icon-bell,
.el-icon-edit,
.el-icon-picture-outline {
  color: rgb(4, 4, 4);
  font-size: 20px;
  border: 0.8px rgb(181, 173, 173) solid;
  padding: 10px;
  border-radius: 10px;
  margin-left: 10px;
}

.el-icon-s-grid:hover,
.el-icon-refresh:hover,
.el-icon-bell:hover,
.el-icon-arrow-down:hover,
.el-icon-edit:hover,
.el-icon-picture-outline:hover {
  border: 0.8px rgba(29, 147, 171) solid;
  background-color: #ededed;
  content: attr(title);
}

@media (max-width: 768px) {

  .el-icon-s-grid,
  .el-icon-refresh,
  .el-icon-bell,
  .el-icon-edit,
  .el-icon-picture-outline {
    font-size: 24px;
    padding: 8px;
    margin-left: 5px;
  }
}

#under {
  border: #c5c5c7 0.8px solid;
  border-radius: 8px 8px 0 0;
  z-index: 10;
  padding: 0 0 0 10px;
  position: fixed;
  bottom: 0;
  background-color: #ffffff;
  height: 115px;
  width: 99%;
  margin-right: 10%;
}

.md {
  display: inline-block;

  max-width: 100%;
  padding-left: 10px;
}

.md .system,
.md .system .v-md-editor-preview {
  min-width: 80%;
  max-width: 80%;
}

.copy {
  border-radius: 5px;
  background-color: #a6deea;
  transition: opacity 1s ease;
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.copy i {
  color: #fff;
}

.system:hover .copy {
  opacity: 1;
}

.v-md-preview {
  margin: 0;
  padding: 0;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding-left: 0;
}

.system /deep/ .github-markdown-body {
  padding: 0 !important;
  font-size: 14px;
}

div /deep/ div[class*="v-md-pre-wrapper-"] {
  position: relative;
  background-color: #c5c5c7 !important;
  border-radius: 5px;
  line-height: 0%;
  margin-bottom: 0;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("//unpkg.com/element-ui@2.15.13/lib/theme-chalk/index.css");

.input {
  bottom: 8px;
  position: fixed;
  min-height: 100px;
}

@media only screen and (max-device-width: 640px) {
  .cent /deep/ .el-drawer {
    width: 80% !important;
    min-width: 150px;
  }

  /* 禁用iPhone中Safari的字号自动调整 */
  html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    /* 解决IOS默认滑动很卡的情况 */
    -webkit-overflow-scrolling: touch;
  }

  /* 禁止缩放表单 */
  input[type="submit"],
  input[type="reset"],
  input[type="button"],
  input {
    resize: none;
    border: none;
  }

  /* 取消链接高亮 */
  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  /* 设置HTML5元素为块 */
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  /* 图片自适应 */
  img {
    width: 100%;
    height: auto;
    width: auto9;
    /* ie8 */
    display: block;
    -ms-interpolation-mode: bicubic;
    /*为了照顾ie图片缩放失真*/
  }

  textarea {
    width: 100%;
    resize: none;
    overflow: hidden;
    font-size: 16px;
    line-height: 1.5em;
    padding: 5px;
    border: 1px solid #ccc;
    min-height: 35px;
    /* max-height: 40vh; */
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  /* 初始化 */
  * {
    padding: 0;
  }

  body {
    /* height: 100vh; */
    height: 100%;
    overflow: hidden;
    /* background: linear-gradient(to bottom right, #1da0e6, #428bb0); */
  }

  .container {
    height: 100vh;
    overflow: auto;
    /* 启用滚动 */
  }

  .send {
    height: 30px;
    margin: 10px;
    background-color: #1d93ab;
    padding: 5px;
    border-radius: 10px;
    border: none;
    padding: 2px;
    border: 0.8px rgb(108, 108, 108) solid;
  }

  .send:hover {
    background-color: #1a849a;
  }

  textarea {
    resize: none;
    overflow: hidden;
    font-size: 16px;
    line-height: 1.5em;
    padding: 10px;
    border: 1px solid #ccc;
    min-height: 35px;
    border: none;
    width: 100%;
    /* max-height: 40vh; */
    border-radius: 5px;
    /* display: none; */
  }

  textarea:focus {
    outline: none;
    /* 隐藏默认的焦点边框 */
    /* border-color: rgb(255, 255, 255); */
    /* 设置焦点边框的颜色 */
    /* box-shadow: 0 0 5px #1D93AB; */
    /* 设置焦点边框的样式 */
  }

  .el-icon-delete {
    float: right;
    margin-right: 10px;
    padding: 10px;
  }

  .el-icon-s-unfold {
    z-index: 2;
    float: left;
    padding: 9px 10px;
    margin-top: 8px;
    margin-right: 20px;
    margin-bottom: 8px;
  }

  .el-icon-s-fold {
    z-index: 2;
    float: right;
    padding: 9px 10px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .version {
    margin-left: 10%;
    font-size: 12px;
  }

  .input_ii {
    margin-bottom: 10px;
    position: absolute;
    bottom: 0px;
    width: 95%;
    margin-top: 30px;
    display: flex;
    border: 1px rgb(82, 74, 74) solid;
    border-radius: 5px;

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .cent {
    margin-top: 1%;
  }

  #app {
    height: 94vh;
    z-index: 0;
    padding: 10px;
  }

  .newsession {
    border: #c0b7b7 2px dashed;
    padding: 5px;
    margin: 0 10px;
    background-color: #ffffff;
    border-radius: 5px;
    width: 30%;
  }

  .newsession:hover {
    border: #0bf317a6 2px dashed;
  }

  /* 隐藏滚动条，但保留垂直滚动 */
  div::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }

  div {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .list_id {
    width: 90%;
    padding: 5px;
    border-radius: 5px;
    border: none;
    /* height: 20%; */
    padding-top: 10px;
    padding-bottom: 10px;
    color: blueviolet;
    border: 1px rgb(200, 195, 195) solid;
  }

  .activeCss {
    background: rgb(162, 218, 240);
    color: #000;
  }

  .list_id:hover {
    border: 1px solid rgba(76, 233, 144, 0.67);
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }

  .account_info {
    margin: 0 40px;
    width: 70%;
    border: none;
    height: 5%;
    background-color: #d8d0d0;
    word-wrap: break-word;
  }

  .mask {
    position: absolute;
    z-index: 2;
    width: 100vw;
    height: 100vh;
    background-color: var(--black-50);
  }

  .account_info:hover {
    background-color: #83838451;
    border-radius: 5px;
  }

  .didi {
    width: 100%;
    margin-bottom: 10px;
    padding: 5px;
    border: none;
    background-color: #e6c0c0;
    border-radius: 5px;
  }

  .system {
    margin-top: 10px;
    padding: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-left: 5%;
    margin-bottom: 10px;
    background-color: #f4f6f8;
    border-radius: 10px;
    margin-right: 20%;
  }

  .user {
    padding: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #d2f9d1;
    border-radius: 10px;
    margin-left: 20%;
    margin-right: 5%;
    max-width: 80%;
  }

  span {
    margin: 2px;
  }
}

@media screen and (max-width: 1920px) {
  body {
    height: 100%;
    overflow: hidden;
  }

  div.sbsb img {
    max-width: 50% !important;
    max-height: 100%;
  }

  /* @import url("//unpkg.com/element-ui@2.15.13/lib/theme-chalk/index.css"); */

  .container {
    height: 100vh;
    overflow: auto;
  }

  .input_ii {
    margin-bottom: 10px;
    position: absolute;
    bottom: 0px;
    width: 95%;
    margin-top: 30px;
    display: flex;
    border: 1px rgb(82, 74, 74) solid;
    border-radius: 5px;

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  textarea {
    resize: none;
    overflow: hidden;
    font-size: 16px;
    line-height: 1.5em;
    padding: 5px;
    border: 1px solid #ccc;
    width: 80%;
    min-height: 35px;
    border: none;
    /* max-height: 40vh; */
    border-radius: 5px;
  }

  textarea:focus {
    outline: none;
  }

  .send {
    height: 30px;
    margin: 10px;
    background-color: #1d93ab;
    padding: 5px;
    border-radius: 10px;
    border: none;
    padding: 2px;
    border: 0.8px rgb(108, 108, 108) solid;
  }

  .send:hover {
    background-color: #1a849a;
  }

  .el-icon-s-unfold {
    z-index: 2;
    float: left;
    padding: 9px 10px;
    margin-top: 8px;
    margin-right: 20px;
    margin-bottom: 8px;
  }

  .el-icon-s-fold {
    z-index: 2;
    float: right;
    padding: 9px 10px;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .el-icon-chat-dot-square {
    color: rgb(4, 4, 4);
    font-size: 50px;
    border: 0.8px rgb(181, 173, 173) solid;
    padding: 10px;
    border-radius: 10px;
    margin-left: 10px;
  }

  .el-icon-chat-dot-square:hover {
    border: 0.8px rgba(29, 147, 171) solid;
    background-color: #ededed;
    content: attr(title);
  }

  .cent {
    margin-top: 1%;
    display: flex;
    flex-direction: row;
    align-content: space-between;
    /* margin-bottom: 50px; */
  }

  #app {
    width: 100%;
    height: 90vh;
    padding: 10px;
  }

  .newsession {
    border: #c0b7b7 2px dashed;
    padding: 5px;
    margin: 0 10px;
    background-color: #e7f8ff;
    border-radius: 5px;
    width: 20%;
  }

  .newsession:hover {
    border: #0bf317a6 2px dashed;
    background-color: #e6e6e6;
  }

  /* 隐藏滚动条，但保留垂直滚动 */
  div::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }

  div {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .list_id {
    width: 90%;
    padding: 5px;
    border-radius: 5px;
    border: none;
    /* height: 20%; */
    padding-top: 10px;
    padding-bottom: 10px;
    color: blueviolet;
    border: 1px #1d93ab solid;
  }

  .activeCss {
    background: skyblue;
  }

  .list_id:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
      rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  }

  /* .list_id:active {
        background-color: rgb(213, 213, 194);
    } */
  .account_info {
    margin: 0 50px;
    width: 70%;

    padding: 10px;
    border: none;
    background-color: #fff;
  }

  .account_info:hover {
    background-color: #cfcfd451;
    border-radius: 5px;
  }

  .didi {
    width: 100%;
    margin-bottom: 10px;
    padding: 5px;
    border: none;
    background-color: #f3e5e5;
    border-radius: 5px;
  }

  .didi:hover {
    background-color: #c0dedf;
  }

  .system {
    padding: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-left: 1%;
    /* border: 1px rgb(235, 131, 131) solid; */
    background-color: #f4f6f8;
    border-radius: 10px;
    margin-top: 10px;
    margin-right: 20%;
  }

  .user {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: #d2f9d1;
    border-radius: 10px;
    padding: 10px;

    margin-top: 10px;
    max-width: 80%;
  }

  span {
    margin: 2px;
  }
}

pre {
  display: block;
  width: 100px;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
<style>
@media (max-width: 768px) {
  .el-message-box {
    width: 300px;
  }

  .el-icon-s-grid,
  .el-icon-refresh,
  .el-icon-bell,
  .el-icon-edit,
  .el-icon-picture-outline {
    font-size: 16px;
    padding: 5px;
    margin-left: 1px;
  }
}
</style>