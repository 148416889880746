<template>
    <div class="WriteEmail">
        <hello 
        :inputContent="inputContent"
        :title="title"
        :title02="title02"
        :systemPrompt="systemPrompt"
        :name_model="name_model"
        @input-change="handleInputChange"
        ></hello>
    </div>
</template>
<script>
import hello from "@/components/tool/helloWorld.vue";
export default {
    components:{
            hello
        },
data() {
    return {
        input: '',
        title: "邮件小助手",
        name_model: "邮件小助手",
        title02:"描述需求，一键生成专业邮件。",
        inputContent: "示例：以生病为由向辅导员请假",
        systemPrompt:"WriteEmail"  //对话模组
    };
},
methods: {
    handleInputChange(val) {
    this.input = val;
    alert(this.input)
    }
}


};
</script>

<style scoped>
</style>