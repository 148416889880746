<template>
    <div class="SumUp">
        <hello 
        :inputContent="inputContent"
        :title="title"
        :title02="title02"
        :systemPrompt="systemPrompt"
        :name_model="name_model"
        @input-change="handleInputChange"
        ></hello>
    </div>
</template>
<script>
import hello from "@/components/tool/helloWorld.vue";
export default {
    components:{
            hello
        },
data() {
    return {
        input: '',
        title: "总结概括小助手",
        name_model: "总结概括",
        title02:"输入需要概括的内容，我们帮你精简总结。",
        inputContent: "示例：随着新能源的快速发展，传统能源交易系统的效率低下、交易不公开透明、数据不可回溯等问题也愈加突出。这些问题导致新能源生产和消费间信息不对称，市场规范化程度较低，交易效率低下，难以实现新能源有序开发与利用",
        systemPrompt:"SumUp"  //对话模组
    };
},
methods: {
    handleInputChange(val) {
    this.input = val;
    alert(this.input)
    }
}


};
</script>

<style scoped>
</style>