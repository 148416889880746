<template>
    <div class="WikiKnowledge">
        <hello 
        :inputContent="inputContent"
        :title="title"
        :title02="title02"
        :systemPrompt="systemPrompt"
        :name_model="name_model"
        @input-change="handleInputChange"
        ></hello>
    </div>
</template>
<script>
import hello from "@/components/tool/helloWorld.vue";
export default {
    components:{
            hello
        },
data() {
    return {
        input: '',
        title: "知识小百科",
        name_model: "知识小百科",
        title02:"请输入不懂的问题或概念，小助手为你科普。",
        inputContent: "示例：熬夜有什么坏处",
        systemPrompt:"WikiKnowledge"  //对话模组
    };
},
methods: {
    handleInputChange(val) {
    this.input = val;
    alert(this.input)
    }
}


};
</script>

<style scoped>
</style>