<template>
  <div>
    <div class="header">
      <h1>ChatGPT 免费使用🥳</h1>
    </div>
    <div class="content">
      <div class="content-head">
        <div class="left" @click="gotohome">返回列表</div>
        <div class="right" @click="history" >历史记录</div>
      </div>
      <div style="margin: 20px">
        <div class="titile">{{ title }}</div>
        <div class="content-content">{{ title02 }}</div>
        <div class="content-footer">
          <div class="input">
            <el-input
              type="textarea"
              :autosize="{ minRows: 5, maxRows: 5 }"
              :placeholder="inputContent"
              v-model="input"
            >
            </el-input>
          </div>
          <div class="batch">
            <el-radio disabled label="禁用">批量生成(1-20篇) (高级会员专享)</el-radio>
          </div>
          <div class="btn" @click="sendMessage()" v-show="button_loding01">提交</div>
          <div class="btn" @click="now_error"  v-show="button_loding">生成中</div>
          <div style="margin-top:20px;margin-bottom:10px" >
            <div class="input" >
              <el-input
                type="textarea"
                :autosize="{ minRows: 5, maxRows: 5 }"
                :placeholder="inputContent"
                readonly="true"
                v-model="response"
              >
              </el-input>
            </div>
            <div class="btn02" @click="copy()" style="margin-top: 10px;">全部复制</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GPTAPI } from "@/config";
const axios = require("axios");
export default {
  props: ["items", "title", "title02", "inputContent","systemPrompt","name_model"],
  data() {
    return {
      input: "",
      response:"这里是ai回答的内容",
      button_loding: false,
      button_loding01: true,
    };
  },
  methods: {
    handleSubmit() {
      this.$emit("input-change", this.input);
    },
    copy() {
      // 获取要复制的文本内容
        let textToCopy = this.response;
        // 创建一个隐藏的 <textarea> 元素
        let textarea = document.createElement('textarea');
        textarea.style.cssText = 'position: absolute; left: -99999px;';
        textarea.value = textToCopy;
        document.body.appendChild(textarea);
        // 选中 <textarea> 元素中的文本内容
        textarea.select();
        // 执行复制命令，并在控制台输出复制结果
        let successful = document.execCommand('copy');
        if (successful) {
            this.$notify({
            title: "copy",
            showClose: true,
            message: "复制成功~",
            type: "success",
            });
        } else {
             this.$notify({
                title: "copy",
                showClose: true,
                message: "没有内容~",
                type: "error",
                });
        }
        // 删除创建的 <textarea> 元素
        document.body.removeChild(textarea);

    },
    gotohome(){
      this.$router.push("/IndexShow");
      window.location.reload();
    },
    history(){
      this.$router.push("/GptApp");
      sessionStorage.setItem('user_session_id',this.name_model);
      sessionStorage.setItem('activeVar',this.name_model)
      window.location.reload();
    },
    // 请求接口
    async sendMessage() {
      const h = this.$createElement;
      if (this.input.trim() === "") {
        const errorMessage = document.createElement("div");
        this.no_Input();
        document.body.appendChild(errorMessage);
        return false;
      }
      if (parseInt(this.number) < 100 && parseInt(this.number) >= 0) {
        this.button_loding = !this.button_loding;
        this.button_loding01 = !this.button_loding01;
        this.$notify({
          title: "提示：",
          message: h(
            "i",
            { style: "color: teal" },
            "您的剩余token小于100，但请求可能大于100个token，为了不影响您的正常体验，请进行兑换token后再进行提问，加Q群602354227免费领取10万token"
          ),
        });
        return "";
      } else if (parseInt(this.number) < 0) {
       
        this.$notify({
          title: "提示：",
          message: h(
            "i",
            { style: "color: teal" },
            "您的剩余token小于0，为了不影响您的正常体验，请进行兑换token后再进行提问，加Q群602354227免费领取10万token"
          ),
        });
        return "";
      } else {
        let token_ = localStorage.getItem('token')
            if (!token_) {
            this.$message({
                message: '并未登陆，正在为您跳转登陆~',
                type: 'error'
            });
            setTimeout(() => {
                this.$router.push("/StartLogin");
                location.reload();
            }, 1000);
            return "";
            }
        this.$notify({
          title: "成功",
          showClose: true,
          message: "发送成功，回复正在书写中~",
          type: "success",
        });
         this.button_loding = !this.button_loding;
        this.button_loding01 = !this.button_loding01;
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: ` ${token}`,
            "X-Requested-With": "XMLHttpRequest",
          },
        };
        const data = {
          user_session_id: this.name_model,
          mess: this.input,
          system_mold: this.systemPrompt,
          continue:false,
          stream_mes:true
        };
        const response = await fetch(GPTAPI + "/testgpt", {
          method: "POST",
          body: JSON.stringify(data),
          headers: config.headers,
        });
        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let result;
         this.response = ""
        do {
          result = await reader.read();
          const chunk = decoder.decode(result.value || new Uint8Array(), {
            stream: !result.done,
          });
          if (chunk !== "assistant") {
            const newContent =this.response + chunk;
            this.response = newContent;

          }
        } while (!result.done);
        this.button_loding = !this.button_loding;
        this.button_loding01 = !this.button_loding01;
        this.remove_number_fun()
      }
      
    },
    remove_number_fun() {
      // 减去剩余number字数
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: ` ${token}`,
          "X-Requested-With": "XMLHttpRequest",
        },
      };
      const data2 = {
        user_session_id: this.name_model,
        continue:false
      };
      axios
        .post(GPTAPI + "/remove_number", data2, config)
        .then((response) => {
          // console.log(response.data)
          this.number = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    no_Input() {
      this.$message.error("未输入内容~");
    },
    now_error() {
      this.$message.error("正在生成中请稍等~");
    },
  },
};
</script>
<style scoped>
.content {
  margin: auto;
  margin-top: 100px;
  width: 70%;
  border: 1px solid rgb(192, 189, 189);
  border-radius: 10px;
  max-width: 1000px;
}
.titile {
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 5px;
}
.content-content {
  color: #7a7a7a;
  font-size: 14px;
}
.content-footer {
  border: 1px solid rgb(192, 189, 189);
  margin: 10px 0;
  padding: 15px;
  border-radius: 10px;
}
.input {
  width: 100%;

  border-radius: 7px;
  min-height: 100px;
}
.batch {
  margin: 10px;
  font-size: 12px;
}
.btn {
  border-radius: 5px;
  padding: 8px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  color: aliceblue;
  background-color: #000000;
}
.btn02 {
  border-radius: 5px;
  padding: 8px;
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  color: rgb(30, 30, 31);
  background-color: #E5E7EB;
}
.btn02:hover {
  background-color: #bbbbbb;
}
.content-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px;

  max-width: 960px;
}
.left,
.right {
  font-size: 14px;
  font-weight: 500;
  background-color: #e5e7eb;
  padding: 8px 20px;
  border-radius: 8px;
}
.left:hover,
.right:hover {
  background-color: #d1cece;
}

@media (max-width: 950px) {
  .content {
    width: 90%;
  }
}
</style>
<style>
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding-left: 30px;
}
.header h1 {
  font-size: 20px;
}
el-input {
  resize: none;
  overflow: hidden;
  font-size: 16px;
  line-height: 1.5em;
  padding: 10px;
  border: 1px solid #ccc;
  min-height: 35px;
  border: none;
  width: 100%;
  border-radius: 5px;
}
  textarea:focus {
    outline: none;
  }
</style>