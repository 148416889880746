<template>
  <div class="WeeklyReport">
    <hello 
    :inputContent="inputContent"
    :title="title"
    :title02="title02"
    :systemPrompt="systemPrompt"
    :name_model="name_model"
    @input-change="handleInputChange"
    ></hello>
</div>
</template>
<script>
import hello from "@/components/tool/helloWorld.vue";
export default {
    components:{
            hello
        },
  data() {
    return {
        input: '',
        title: "周报生成器🔥",
        name_model: "周报生成器",
        title02:"输入工作内容，帮你快速完成周报。",
        inputContent: "示例：上线了更多更能，优化代码效率，增加更多的功能",
        systemPrompt:"WeeklyReport" 
    };
  },
  methods: {
    handleInputChange(val) {
      this.input = val;
      alert(this.input)
    }
  }
  
  
};
</script>

<style scoped>
</style>