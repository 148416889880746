<template>
    <div class="WriteArticles">
        <hello 
        :inputContent="inputContent"
        :title="title"
        :title02="title02"
        :systemPrompt="systemPrompt"
        :name_model="name_model"
        @input-change="handleInputChange"
        ></hello>
    </div>
</template>
<script>
import hello from "@/components/tool/helloWorld.vue";
export default {
    components:{
            hello
        },
data() {
    return {
        input: '',
        title: "写文章小助手🔥",
        name_model: "写文章",
        title02:"请输入需要生成的文章关键词，小助手为你生成一篇原创文章。",
        inputContent: "示例：中国传统节日",
        systemPrompt:"WriteArticles"  //对话模组
    };
},
methods: {
    handleInputChange(val) {
    this.input = val;
    alert(this.input)
    }
}


};
</script>

<style scoped>
</style>