<template>
    <div class="PraisingOthers">
        <hello 
        :inputContent="inputContent"
        :title="title"
        :title02="title02"
        :systemPrompt="systemPrompt"
        :name_model="name_model"
        @input-change="handleInputChange"
        ></hello>
    </div>
</template>
<script>
import hello from "@/components/tool/helloWorld.vue";
export default {
    components:{
            hello
        },
data() {
    return {
        input: '',
        title: "夸夸小助手",
        name_model: "夸人",
        title02:"描述想夸的人，我们来帮你夸。",
        inputContent: "示例：善良的小女孩",
        systemPrompt:"PraisingOthers"  //对话模组
    };
},
methods: {
    handleInputChange(val) {
    this.input = val;
    alert(this.input)
    }
}


};
</script>

<style scoped>
</style>