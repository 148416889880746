<template>
    <div class="StartLogin" style="background-color:#EBEEF2;" >
            <div  id="app" style="display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; " >
                <div class="content" style="display:block;">
                    <div class="login-wrapper">
                        <h1>智能聊天室</h1>
                        <h2>欢迎登陆，今天是个不错的日子</h2>
                        <div class="login-form">
                            <div class="username">
                                <el-input
                                                    placeholder="请输入用户名或邮箱"
                                                    v-model="username"
                                                    clearable>
                                                    </el-input>
                                <!-- <input type="text" class="input-item" v-model="username" > -->
                            </div>
                            <div class="password">
                     
                                <el-input placeholder="请输入密码" v-model="password" show-password></el-input>
                                <!-- <input type="password" class="input-item" v-model="password"> -->
                            </div>
                            <div class="divider">
                                    <a @click="register()">没有账号？立即注册</a>
                                    &nbsp;&nbsp;&nbsp;    &nbsp;&nbsp;&nbsp;    &nbsp;&nbsp;&nbsp;    
                             <span type="primary" class="divider" @click="visible=true">忘记密码</span>
                                
                             
                             <el-dialog title="忘记密码" :visible.sync="visible" width="80%" height="300px" @close="closeDialog('userform')">
                                    <el-form :model="form" ref="userform">
                                        <el-form-item label="邮箱:" > <br>
                                            <el-input v-model="form.email" style="width:83%;"></el-input>
                                        </el-form-item>
                                        <el-form-item label="验证码:" prop="auth_code" style="width: 100%"> 
<br>
                                            <el-input v-model="form.auth_code" style="width: 50%;"></el-input>
                                            <el-button class="get_auth" @click.prevent="get_auth_code()" >获取验证码</el-button>
                                        </el-form-item>
                                        <el-form-item label="新密码:" prop="newpassword"> <br>
                                            <el-input v-model="form.new_password" style="width:80%;"></el-input>
                                        </el-form-item>
                                    </el-form>
                                    <div slot="footer" class="dialog-footer">
                                        <el-button @click="closeDialog('userform')">取 消</el-button>
                                        <el-button type="primary" @click="changepassword()">确 定</el-button>
                                    </div>
                                </el-dialog>
                            </div>
                           
                            <div class="login-btn" @click="login()">登 录</div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
import{ GPTAPI} from "@/config";
var { expressjwt:jwt } = require("express-jwt")
const axios = require('axios');
    export default({
            el: '#app',
            data() {
                return {
                    GPTAPI:GPTAPI,
                    isAuthenticated: false,
                    username: '',
                    password: '',
                    email:'',
                    auth_code: "",
                    visible:false,
                    newpassword: '',
                    form:{
                            email:undefined,
                            auth_code:undefined,
                            password:undefined,
                            new_password:undefined
                        }
                    }
            },
            methods: {
                closeDialog(form){
                    //关闭弹窗
                    this.visible=false
                    //清空表单
                    this.$refs[form].resetFields()
                },
                changepassword() {
                const token = localStorage.getItem("token");
                const config = {
                    headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: ` ${token}`,
                    "X-Requested-With": "XMLHttpRequest",
                    },
                };
                const data = {
                    email:this.form.email,
                    new_password: this.form.new_password,
                    auth_code: this.form.auth_code,
                };
                axios
                    .post(GPTAPI + "/forgot_password", data, config)
                    .then((response) => {
                    console.log(response.data);
                    if (response.data.code == 200) {
                        this.$notify({
                        title: "成功",
                        message: "修改成功~",
                        type: "success",
                        });
                        this.visible=false
                    }
                    else {
                        alert( response.data.mes)
                        this.$notify.info({
                        title: "消息",
                        message: "修改失败",
                        });
                        this.forget
                    }
                    })
                    .catch((error) => {
                    console.log(error);
                    });
                },
                forget(){
                     this.$message({
                        message: '此功能正在添加中,如果需要人工帮你找回可以进,QQ交流群：602354227',
                        type: 'warning'
                        });
                },
                 // 添加 token 验证逻辑
                verifyToken(token, secret) {
                    // 解码 token，验证是否过期以及签名是否正确
                    const decoded = jwt.verify(token, secret);
                    // 返回 token 包含的信息
                    return decoded;
                },
                register(){
                    this.$router.push("/StartRegister")
                    window.location.reload()
                },
                get_auth_code() {
                    const data = {
                        email: this.form.email
                    }
                    axios.post(GPTAPI+'/get_auth_code', data)
                        .then(response => {
                            console.log(response.data)
                           
                            if (response.data.mes == 200) {
                                    this.$message({
                                    message: '获取成功，注意查看您的验证码',
                                    type: 'success'
                                    });
                            }
                            else {
                                alert(response.data)
                            }
                        })
                        .catch(error => {
                            console.log(error.response)
                        })
                },
                login() {
                    const data = {
                        username: this.username,
                        password: this.password
                    }
                    axios.post(GPTAPI+"/login", data)
                        .then(response => {
                            const { code, data: { token } } = response.data
                            if (code === 200 && token) {
                                localStorage.setItem('token', token)
                                 window.location.href ='#/GptApp';
                                 this.$message({
                                    showClose: true,
                                    message: '登陆成功',
                                    type: 'success'
                                    });
                            }else if(code === "2000" && token) {
                                // 将token存储在自己浏览器中
                                localStorage.setItem('token', token)
                                // 跳转至登录成功后的用户界面
                                this.$router.push('/AdminUser')
                                location.reload();
                                this.$message({
                                message: '登录成功',
                                type: 'success'
                                });
                            }
                            else {
                                (this.$message({
                                    message: '账号或密码错误！',
                                    type: 'error'
                                  }))
                                localStorage.setItem('token', "")
                            }
                        })
                }
            },
            mounted() {
                const token = localStorage.getItem('token')
                if (token) {
                  this.$message({
                  message: '欢迎回来~',
                  type: 'success'
                });
                    this.$router.push('/GptApp')
                    location.reload();
                }
            },
        })
        
</script>

<style scoped>

.StartLogin{
    background-color:#0e71f1;

}
.content{
    background-color: #fff;
border-radius: 5px ;
}

.login-form{
    margin: 30px;
}

.divider{
    border-radius: 5px;
    font-size: 12px;
    margin-bottom: 20px;
    color: #4C78DD;
}

/*///////////////////////*/
.content{
        padding: 30px;
        height: 40%;
        width: 80%;
    }
    h2{
    font-weight: 300;
    color:#a8b0bb ;
}   
h1{
    font-size: 20px;
}
h2{
    font-size: 14px;
}
p{
    font-size: 12px;
}
.username{
    margin: 10px 0;
}
.password{
    margin: 10px 0;
}
.login-wrapper h1,.login-wrapper h2{
    margin: 10px ;
    margin-left: 30px ;
}
.login-btn{
    width: 20%;
    background-color: #D2DDF7;
    text-align:center;
    font-size: 15px;
    font-weight: 600;
    padding: 5px;
    border-radius: 5px ;

}
/*<!-- /////////////////////// --> */

@media (min-width:1000px) {
    .content{
        padding: 100px;
        padding-top: 0;
        padding-bottom: 120px;
        height: 40%;
        width: 50%;
    }
    .username{
    margin: 30px 0;
}
.password{
    margin: 30px 0;
}

    h2{
    font-weight: 300;
    color:#a8b0bb ;
}
    .login-wrapper h1,.login-wrapper h2{
    margin: 30px ;
    }
}
@media (max-width:740px) {
    .content{
        padding: 30px;
        height: 40%;
        width: 80%;
    }
    h2{
    font-weight: 300;
    color:#a8b0bb ;
}   
h1{
    font-size: 20px;
}
h2{
    font-size: 14px;
}
p{
    font-size: 12px;
}
.username{
    margin: 10px 0;
}
.password{
    margin: 10px 0;
}
.login-wrapper h1,.login-wrapper h2{
    margin: 10px ;
    margin-left: 30px ;
}
.login-btn{
    width: 100%;
    background-color: #D2DDF7;
    text-align:center;
    font-size: 15px;
    font-weight: 600;
    padding: 5px;
    border-radius: 5px ;

}
}

</style>
<style>
@media (max-width: 768px) {
  .el-message-box {
    width: 300px;
  }
}
</style>