<template>
    <div class="DreamInterpretation">
        <hello 
        :inputContent="inputContent"
        :title="title"
        :title02="title02"
        :systemPrompt="systemPrompt"
        :name_model="name_model"
        @input-change="handleInputChange"
        ></hello>
    </div>
</template>
<script>
import hello from "@/components/tool/helloWorld.vue";
export default {
    components:{
            hello
        },
data() {
    return {
        input: '',
        title: "解梦小助手",
        name_model: "解梦",
        title02:"描述你的梦境，小助手将为你作出解析。",
        inputContent: "示例：林中有一条小溪，数不胜数的柳树环绕着它，柳树的枝条在风中摇曳，仿佛一位身材修长的女子在溪边梳拭她那亮丽柔顺的秀发。水中柳枝的倒影，参差不齐，微风吹过，时而摆动，叶片落在水面上，顺水漂远，仿佛一叶扁舟，承载着柳树的心念，漂向远方。",
        systemPrompt:"DreamInterpretation"  //对话模组
    };
},
methods: {
    handleInputChange(val) {
    this.input = val;
    alert(this.input)
    }
}


};
</script>

<style scoped>
</style>