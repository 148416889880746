<template>
<div class="Register" style="background-color:#EBEEF2;" >
     <div  id="app" style="display: flex;

 
  height: 100vh; " >
                <div style="display:block;" class="content">
                    <div class="register-wrapper">
                        <h1>智能聊天室</h1>
                        <h2>注册属于你自己的账户</h2>
                        <div class="login-form">
                            <div class="username">
                                <el-input
                                                    placeholder="请输入用户名"
                                                    v-model="username"
                                                    clearable>
                                                    </el-input>
                            </div>
                            <div class="password">
                                <el-input placeholder="请输入密码" v-model="password" show-password></el-input>
                            </div>
                            <div class="username">
                                <el-input
                                                    placeholder="邮箱"
                                                    v-model="email"
                                                    clearable>
                                                    </el-input>
                            </div>
                            <div class="username">
                                
                                <el-input style="width:60%"
                                                    placeholder="验证码"
                                                    v-model="auth_code"
                                                    clearable>
                                                    </el-input>
                                                    <span class="get_auth" @click.prevent="get_auth_code()">获取验证码</span>
                            </div>
                            <div class="username">
                                
                                <el-input  style="width:86%"
                                                    placeholder="邀请人ID【可选填】"
                                                    v-model="inviter"
                                                    clearable>
                                                    </el-input>
                                                    <el-popover
                                                        placement="bottom"
                                                        title="提示："
                                                        width="200"
                                                        trigger="click"
                                                        content="填写邀请人ID，若邀请人ID存在则双方都得3万token，选填项">
                                                        <i class="el-icon-question" slot="reference" style="font-size:20px;padding:5px"></i>
                                                    </el-popover>
                                                    <!-- <el-button slot="reference">click 激活</el-button> -->
                                                    
                            </div>
                            <div class="divider">
                                    <a @click="login()">已有账号，立即登陆</a> 
                            </div>
                           
                            <div class="login-btn" @click="register()">注册</div>
                        </div>
                        
                    </div>
                </div>
            </div>
</div>
</template>

<script>
import{ GPTAPI} from "@/config";
const axios = require('axios');
    export default({
        el: '#app',
        data() {
            return {
                 GPTAPI:GPTAPI,
              inviter:"",
                username: '',
                password: '',
                email: "",
                auth_code: "",
                last_get_time: localStorage.getItem('last_get_time') || 0
            }
        },
        methods: {
            register() {
                if (this.username == '' || this.password == '' || this.email == '' || this.auth_code == '') {
               
                    this.$message({
          message: '有未填写内容，请填写后提交',
          type: 'warning'
        });
                    return ''
                }
               let data = {
                username: this.username,
                password: this.password,
                email: this.email,
                auth_code: this.auth_code,
                inviter: this.inviter
              }
              // if (this.inviter === null) {
              //   delete data.inviter; // 如果 inviter 为空，则从请求参数中删除该属性
              // }
                const config = {
                            headers: {
                            "Content-Type": "application/json",
                            Accept: "application/json",
                            
                            "X-Requested-With": "XMLHttpRequest",
                            },
                        };
                axios.post(GPTAPI+'/register', data,config)
                    .then(response => {
                        console.log(response.data)
                        // 处理接口返回的数据
                        if (response.data.code == "200") {
                           this.$message({
                            message: '注册成功',
                            type: 'success'
                          });
                            this.$router.push("/StartLogin")

                        } else {
                            alert(response.data.data)
                        }
                    })
                    .catch(error => {
                        console.log(error.response)
                      
                    })
            },
            get_auth_code() {
                const data = {
                    email: this.email
                }
                axios.post(GPTAPI+'/get_auth_code', data)
                    .then(response => {
                        console.log(response.data)
                        if (response.data.mes == 200) {

                            this.$message({
          message: '获取成功，注意查看您的验证码',
          type: 'success'
        });

                        }
                        else {
                            alert(response.data)
                        }
                    })
                    .catch(error => {
                        console.log(error.response)
                    })
            },
            login(){
                this.$router.push("/StartLogin")
                window.location.reload()
            }
        }
    })
</script>
<!-- <style src="@/static/css/register.css"  scoped></style> -->
<style scoped>
   .get_auth{
    font-size: 13px;
     background-color:#D2DDF7;
     text-align:center;
    margin-left: 10px;
    padding: 5px;
    border-radius: 5px ;
     
   }
.StartLogin{
    background-color:#0e71f1;

}


.register-wrapper{

    background-color: #FFFFFF;
    border-radius: 5px ;
    padding: 10px;
    width: 100%;
}

.content{
border-radius: 5px ;
}

.login-form{
    margin: 30px;
}

.divider{
    font-size: 12px;
    margin-bottom: 20px;
    color: #4C78DD;
}

/*///////////////////////*/
.content{
        padding: 30px;
        height: 40%;
        width: 80%;
    }
    h2{
    font-weight: 300;
    color:#a8b0bb ;
}   
h1{
    font-size: 20px;
}
h2{
    font-size: 14px;
}
p{
    font-size: 12px;
}
.username{
    margin: 10px 0;
}
.password{
    margin: 10px 0;
}

.register-wrapper h1,.register-wrapper h2{
    margin: 10px ;
    margin-left: 30px ;
}
.login-btn{

    width: 100%;
    background-color: #D2DDF7;
    text-align:center;
    font-size: 15px;
    font-weight: 600;
    padding: 5px;
    border-radius: 5px ;

}
/*<!-- /////////////////////// --> */

@media (min-width:1000px) {
    .register-wrapper{
        width: 100%;
        margin:100px;
    }
    .content{
        padding: 0px;
        height: 40%;
        width: 70%;
    }
    .username{
    margin: 30px 0;
}
.password{
    margin: 30px 0;
}

    h2{
    font-weight: 300;
    color:#a8b0bb ;
}
    .register-wrapper h1,.register-wrapper h2{
    margin: 30px ;
    }
    .login-btn{
    width: 20%;}
}
@media (max-width:740px) {
.register-wrapper{
    width: 100%;
}
    
    h2{
    font-weight: 300;
    color:#a8b0bb ;
}   
h1{
    font-size: 20px;
}
h2{
    font-size: 14px;
}
p{
    font-size: 12px;
}
.username{
    margin: 10px 0;
}
.password{
    margin: 10px 0;
}
.register-wrapper h1,.register-wrapper h2{
    margin: 10px ;
    margin-left: 30px ;
}
    .login-btn{
    width: 100%;}
}

</style>