<template>
    <div class="CopyWriting">
        <hello 
        :inputContent="inputContent"
        :title="title"
        :title02="title02"
        :systemPrompt="systemPrompt"
        :name_model="name_model"
        @input-change="handleInputChange"
        ></hello>
    </div>
</template>
<script>
import hello from "@/components/tool/helloWorld.vue";
export default {
    components:{
            hello
        },
data() {
    return {
        input: '',
        title: "朋友圈文案生成器🔥",
        name_model: "朋友圈文案生成",
        title02:"输入关键词，生成吸引人的朋友圈文案。",
        inputContent: "示例：吃大餐",
        systemPrompt:"CopyWriting"  //对话模组
    };
},
methods: {
    handleInputChange(val) {
    this.input = val;
    alert(this.input)
    }
}


};
</script>

<style scoped>
</style>