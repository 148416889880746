<template>
  <div class="UserInformation" v-loading.fullscreen.lock="fullscreenLoading">
    <div id="app" style="font-size: 15px">
      <!-- <div class="container" style="padding: 30px;">
                <h1>账号信息</h1>
                <h2>UID：{{uid}}</h2>
                <h2>用户名：{{username}}</h2>
                <h3>邮箱：{{email}}</h3>
                <p>这是您的账号信息页面。您可以在此查看和修改您的个人信息。</p>

                <span>旧密码:</span><input type="text" v-model="old_password" >
                <br>
                <span>新密码:</span><input type="password" v-model="new_password">
            <br>
            <span>再次输入新密码:</span><input type="password" v-model="new2_password">
            <br>
                <div class="el-icon-thumb" @click="changepassword">修改密码</div>
            </div>
            <div class="content">
                <p>选择兑换的token数</p>
                <select v-model="add_number">
                    <option value="10000">10000</option>
                    <option value="30000">30000</option>
                    <option value="50000">50000</option>
                    <option value="100000">100000</option>
                    <option value="1000000">1000000</option>
                </select>
                <p>所选字符：{{ add_number }}</p>
                <p>所需金额：{{ required_amount }} 元</p>
                <p>剩余余额：{{balance}}</p>
                <p>剩余token：{{number}}</p>
                <button class="el-icon-sold-out" @click="exchange">确认兑换</button>
                <button class="el-icon-s-custom" @click="chong">退出登录</button>
    <button class="el-icon-house" @click="gohome"> 回到首页</button>
            </div> -->
      <div  id="app">
        <div  class="content">
          <div  class="content-content">
            <div class="img-container">
                  <img
                    :src=qq
                    alt=""
                    width="64px"
                    style="border-radius: 50%; border: 4px solid white"
                  />
                  <img :src="infoimg" :width="wid"  style="margin-left:100px">
                </div>
                
            <div
              style="
                color: rgb(255, 255, 255);
                font-size: 26px;
                font-weight: 600;
                margin-top: 10px;
              "
            >
              个人中心
            </div>
            <div
              
              style="
                color: rgb(207, 217, 228);
                font-size: 16px;
                font-weight: 600;
                margin: 10px;
              "
            >
              UID：{{ uid }}
            </div>
            <div  class="content-footer">
              <div  class="gotochat" @click="gohome()">返回聊天</div>
              <div  class="goout" @click=" chong()">退出登陆</div>
            </div>
          </div>
        </div>
        <div  class="block-content">
          <div
            
            style="
              color: rgb(226, 233, 250);
              font-size: 17px;
              font-weight: 600;
              margin-bottom: 5px;
            "
          >
            对话token剩余：{{ number }} 个
          </div>
          <div  class="add_token"  @click="dialogVisible = true">领取token</div>
          <div  class="add_token"  @click="dialogVisible2 = true" style="margin-top:10px">兑换token</div>

          <el-dialog
                title="提示"
                :visible.sync="dialogVisible"
                width="80%"
                :before-close="handleClose">
                <p style="color:rgb(209, 156, 9)">邀请好友注册填写你的UID即刻获得4万-10万token，被邀请人获得3万-8万toke</p>
                <p style="color:rgb(252, 152, 3)">进QQ交流群免费领10万token</p>
                <p style="color:rgb(19, 190, 13)">QQ群：602354227</p>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
                </span>
                </el-dialog>
                
                <div  class="add_token"  @click="signin" style="margin-top:10px">签到</div>

                
                <el-dialog
                  title="提示"
                  :visible.sync="dialogVisible2"
                  width="80%"
                  :before-close="handleClose">
                  <div>
                <p>选择兑换的token数</p>
                <select v-model="add_number">
                    <option value="100000">100000</option>
                    <option value="1000000">1000000</option>
                    <option value="10000000">10000000</option>
                    <option value="100000000">100000000</option>
                </select>
                <p>所选Token数：{{ add_number }}</p>
                <p>所需金额：{{ required_amount }} 元</p>
                <p>剩余余额：{{balance}} (赞助后备注uid)</p>
                <p>剩余token：{{number}}</p>
                <p>token永久有效 , 单次充值达100 , 自动升级gpt4权限</p>
                <button class="el-icon-sold-out" @click="exchange">确认兑换</button>
            </div>
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible2 = false">取 消</el-button>
                    <el-button type="primary" @click="dialogVisible2 = false" >确 定</el-button>
                  </span>
                </el-dialog>
        </div>
        
        <div  class="footer">
          <div  class="userinfo">用户信息</div>
          <div  class="footer-content">
            您的账户的重要信息
          </div>
          <div >
            <div  class="pp">
              用户名
              <el-input v-model="username"  readonly="true"  ></el-input>
            </div>
            <div  class="pp">
              邮箱
              <el-input v-model="email"  readonly="true" ></el-input>
            </div>
            <div >
              <div  class="newold">
                旧密码
               <el-input v-model="old_password" ></el-input>
              </div>
              <div  class="newold">
                新密码
                <el-input v-model="new_password" ></el-input>
              </div>
            </div>
            <span  class="update" @click="changepassword()">更新密码</span>
            
                
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GPTAPI } from "@/config";
const axios = require("axios");
export default {
  el: "#app",
  data() {
    return {
        qq:"123456",
        dialogVisible:false,
      GPTAPI: GPTAPI,
      add_number: "100000",
      balance: "0",
      number: "",
      uid: "",
      username: "",
      email: "",
      new_password: "",
      new2_password: "",
      old_password: "",
      fullscreenLoading: false,
      dialogVisible2: false,
      infoimg:require('@/assets/vip.png'),
      infoSvip:require('@/assets/svip.png'),
      infovip:require('@/assets/vip.png'),
      wid:'30px',

    };
  },
  computed: {
    required_amount() {
      return this.add_number / 100000;
    },
  },
  methods: {
    chong() {
      localStorage.setItem("token", "");
      window.localStorage.clear();
      this.$notify({
        title: "成功",
        message: "退出登录成功~",
        type: "success",
      });
      this.$router.push("/StartLogin");
      location.reload();
     
    },
    gohome() {
      this.$router.push("/GptApp");
    },
    exchange() {
      // 确认兑换按钮点击事件处理函数
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: ` ${token}`,
          "X-Requested-With": "XMLHttpRequest",
        },
      };
      const data = {
        add_number: this.add_number,
        required_amount: this.required_amount,
      };
      // 发送兑换请求
      if (this.balance < this.required_amount) {
        this.$notify({
          title: "警告",
          message: "余额不足无法兑换",
          type: "warning",
        });
        return "";
      }
      axios
        .post(GPTAPI + "/balance_to_number", data, config)
        .then((response) => {
          this.$notify({
            title: "成功",
            message:response.data,
            type: "success",
          });
          location.reload();
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    signin(){
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: ` ${token}`,
          "X-Requested-With": "XMLHttpRequest",
        },
      };
      const data = {};
      axios
        .post(GPTAPI + "/signin", data, config)
        .then((response) => {
            this.$message({
          showClose: true,
          message: response.data,
          type: 'success'
        });
        })
    },
    changepassword() {
      if (this.old_password.trim() === ""|| this.new_password.trim() === "") {
        this.$message.error("有必填项未填写");
        return false;
      }
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: ` ${token}`,
          "X-Requested-With": "XMLHttpRequest",
        },
      };
      const data = {
        new_password: this.new_password,
        old_password: this.old_password,
      };
      axios
        .post(GPTAPI + "/change_password", data, config)
        .then((response) => {
          console.log(response.data);
          if (response.data.code == 202) {
            this.$notify({
              title: "警告",
              message: "旧密码错误",
              type: "warning",
            });
          }
          if (response.data.code == 200) {
            this.$notify({
              title: "成功",
              message: "修改成功~",
              type: "success",
            });
          }
          if (response.data.code == 444) {
            this.$notify.info({
              title: "消息",
              message: response.data.data,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  async created() {
    this.fullscreenLoading = await true;
    await sleep(500);
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: ` ${token}`,
        "X-Requested-With": "XMLHttpRequest",
        "Access-Control-Allow-Origin": "*",
      },
    };
    var data = {};
    // 获取用户信息
    axios
      .post(GPTAPI + "/get_user_infomation", data, config)
      .then((response) => {
        this.uid = response.data.uid;
        this.email = response.data.email;
        this.username = response.data.username;
        this.number = response.data.number;
        this.balance = response.data.balance;
        if(response.data.role == "svip"){
          this.infoimg = require('@/assets/svip.png');
          this.wid = "80px"
        }
      
         if (/^\w+@qq\.com$/.test(this.email)) {
                const qqnum = this.email.match(/^(\w+)@qq\.com$/)[1];
                this.qq =  "https://q.qlogo.cn/g?b=qq&nk="+qqnum+"&s=100" ;
            } else {
                this.qq =   "https://q.qlogo.cn/g?b=qq&nk=123456&s=100"
            }
      });
    this.fullscreenLoading =await false;
  },
};

const sleep = (timeout) => {
  return new Promise((resolve)=>{
    setTimeout(()=>{
      resolve();
    }, timeout)
  })
}
</script>

<style scoped>
.userinfo{
    color: #2c3138;
    font-size: 19px;
    font-weight: 600;
}
.footer-content{
    margin-top: 5px;
    color: #334155;
    font-size: 14px;
    font-weight: 500;
}
.newold[data-v-0bd06def], .pp{
    color: #313131;
    font-size: 17px;
    margin-top: 5px;
}
.app{
   padding: 30px; 
}
.content{
    border-radius: 6px;
    margin: 30px auto;
    padding: 19px;
    background-color: #6b737f;
    height: 212px;
    max-height: 250px;
    width: 80%;
    max-width: 1280px;
}
.content-content {
    padding: 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;}
    .block-content {
    border-radius: 6px;
    margin: 30px auto;
    padding: 19px;
    background-color: #8ba7e9;
    height: 120px;
    max-height: 150px;
    width: 80%;
    max-width: 1280px;
}
.block-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.add_token{
    color: #e2e9fa;
    font-size: 15px;
    font-weight: 600;
    border: 2px solid #e2e2e2;
    padding: 3px 10px;
    border-radius: 5px;
}
.footer {
    box-shadow: 0 1px 3px 0 rgba(0,0,0,.02), 0 0 0 1px rgba(27,31,35,.15);
    border-radius: 6px;
    margin: 30px auto;
    padding: 19px;
    background-color: #fff;
    height: auto;
    max-height: 500px;
    width: 80%;
    max-width: 1280px;
}
.content-footer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.goout:hover, .gotochat:hover {
    background-color: #d4d6da;
}
.goout, .gotochat {
    font-size: 15px;
    background-color: #ebeef2;
    border-radius: 5px;
    padding: 6px 15px;
    margin: 5px;
    font-weight: 600;
}
.update {
    margin-top: 10px;
    color: #37508b;
    background-color: #d2ddf7;
    font-size: 17px;
    font-weight: 600;
    height: 25px;
    padding: 3px 10px;
    border-radius: 5px;
}
</style>
<style>
.img-container {
  position: relative;
}

.img-container img:first-child {
  position: relative;
  z-index: 0;
}

.img-container img:nth-child(2) {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0;
}
</style>