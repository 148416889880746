<template>
    <div class="HighEQ">
        <hello 
        :inputContent="inputContent"
        :title="title"
        :title02="title02"
        :systemPrompt="systemPrompt"
        :name_model="name_model"
        @input-change="handleInputChange"
        ></hello>
    </div>
</template>
<script>
import hello from "@/components/tool/helloWorld.vue";
export default {
    components:{
            hello
        },
data() {
    return {
        input: '',
        title: "高情商回复小助手🔥",
        name_model: "高情商回复",
        title02:"输入收到的不好回答的问题，小助手为你生成一段高情商的回复。",
        inputContent: "示例：你好像胖了",
        systemPrompt:"HighEQ"  //对话模组
    };
},
methods: {
    handleInputChange(val) {
    this.input = val;
    alert(this.input)
    }
}


};
</script>

<style scoped>
</style>