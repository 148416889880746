<template>
    <div class="ZhihuStyle">
        <hello 
        :inputContent="inputContent"
        :title="title"
        :title02="title02"
        :systemPrompt="systemPrompt"
        :name_model="name_model"
        @input-change="handleInputChange"
        ></hello>
    </div>
</template>
<script>
import hello from "@/components/tool/helloWorld.vue";
export default {
    components:{
            hello
        },
data() {
    return {
        input: '',
        title: "知乎风格问答器",
        name_model: "知乎风格问答",
        title02:"小助手用知乎文体回答你的问题。",
        inputContent: "示例：Python如何学习",
        systemPrompt:"ZhihuStyle"  //对话模组
    };
},
methods: {
    handleInputChange(val) {
    this.input = val;
    alert(this.input)
    }
}


};
</script>

<style scoped>
</style>