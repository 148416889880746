import { render, staticRenderFns } from "./WikiKnowledge.vue?vue&type=template&id=907c6826&scoped=true&"
import script from "./WikiKnowledge.vue?vue&type=script&lang=js&"
export * from "./WikiKnowledge.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "907c6826",
  null
  
)

export default component.exports