<template>
  <div class="Index">
    <div id="app">
      <div data-v-b748e432="" id="app">
        <div   data-v-b748e432="" id="app">
          <div   class="header">
            <h1  >AI 智能 🥳</h1>
            <div class="gptapp" @click="goto"> <i class="el-icon-thumb" style="color:rgb(54, 52, 201)"></i>ChatGPT聊天室</div>
          </div>
          <div   style="padding: 80px 3% 3%">
            <div
               
              style="
               
                color: black;
                font-size: 16px;
                font-weight: 600;
                text-align: center
              "
            >
              效率工具
            </div>
            <div   class="wrapper">
              <div    class="block" @click="gotoWeekReprot">
                周报生成器🔥
                <p  >输入工作内容，帮你快速完成周报。</p>
              </div>
              <div    class="block" @click="gotoDailyReport">
                日报生成
                <p  >输入工作内容，帮你快速完成日报。</p>
              </div>
              <div    class="block" @click="gotoWriteProgramme">
                写方案小助手🆕
                <p  >提供方案关键词，助你生成完整方案。</p>
              </div>
              <div    class="block" @click="gotoWriteEmail">
                邮件小助手
                <p  >描述需求，一键生成专业邮件。</p>
              </div>
              <div    class="block" @click="gotoReadingAnalysis">
                阅读小助手
                <p  >输入难懂段落，让我们一起解读。</p>
              </div>
              <div    class="block" @click="gotoPowerPoint">
                PPT大纲生成器🔥
                <p  >
                  请输入需要生成的PPT关键词或标题，为你生成一份完整的PPT大纲。
                </p>
              </div>
              <div    class="block" @click="gotoOptimizeResume">
                简历优化小助手
                <p  >
                  输入需要优化的简历描述或关键词，帮你写出更有条理的简历。
                </p>
              </div>
              <div    class="block" @click="gotoCodeOptimization">
                代码优化询问🔥
                <p  >
                  输入需要优化的代码片段，可以帮你检查bug或者告诉你代码还可以优化的地方。
                </p>
              </div>
              <div    class="block" @click="gotoSolidityDetection">
                Solidity合约检测🆕
                <p  >
                  输入需要优化的Solidity合约，可以帮你检查bug或者告诉你智能合约还可以优化的地方。
                </p>
              </div>
              <div    class="block" @click="gotoCodeWriting">
                代码撰写询问🔥
                <p  >
                  输入需要撰写的代码要求，可以帮你撰写相关代码。
                </p>
              </div>
              <div    class="block" @click="gotoEnglishWriting">
                英文写作小助手
                <p  >输入英文或中文，帮你润色成优美的英文。</p>
              </div>
              <div    class="block" @click="gotoMermaidFlowchart">
                mermaid流程图一键生成
                <p  >输入你想得到的流程图的话题或者内容。</p>
              </div>
              <div    class="block" @click="gotoSWOT">
                SWOT分析法
                <p  >
                  请详细描述目前事件的情势，小助手会帮你生成一份SWOT分析报告。
                </p>
              </div>
              <div    class="block" @click="gotoOKR">
                OKR生成器
                <p  >
                  输入你要完成的目标，小助手为你生成完整的OKR规划。
                </p>
              </div>
              <div    class="block" @click="gotoSumUp">
                总结概括小助手
                <p  >输入需要概括的内容，我们帮你精简总结。</p>
              </div>
              <div    class="block" @click="gotoShortVideo">
                短视频脚本生成器🔥
                <p  >
                  输入想要拍摄的主题，小助手为你生成一个短视频脚本。
                </p>
              </div>
              <div    class="block" @click="gotoRedStyle">
                小红书风格模拟器🔥
                <p  >
                  输入你想发布的内容，帮你生成小红书的风格。
                </p>
              </div>
              <div    class="block" @click="gotoRedTitle">
                小红书标题生成器🔥
                <p  >
                  输入需要起标题的文案,小助手为你生成五个供参考的标题。
                </p>
              </div>
              <div    class="block" @click="gotoWriteArticles">
                写文章小助手🔥
                <p  >
                  请输入需要生成的文章关键词，小助手为你生成一篇原创文章。
                </p>
              </div>
              <div    class="block" @click="gotoMakeTitle">
                起标题小助手
                <p  >
                  输入需要起标题的文章，或大致内容，小助手帮你想五个好标题。
                </p>
              </div>
              <div    class="block" @click="gotoArticleSummary">
                微信文章总结
                <p  >
                  帮你总结概括微信文章内容，直接粘贴链接到输入框，无需输入额外文字。
                </p>
              </div>
            </div>
            <div
               
              style="
                 
                color: black;
                font-size: 16px;
                font-weight: 600;
                text-align: center
              "
            >
              生活
            </div>
            <div   class="wrapper">
                <div    class="block" @click="gotoTarotPrediction">
                    塔罗牌预测小助手
                    <p  >说出占卜需求，预测你的运势。</p>
                </div>
                <div    class="block" @click="gotoCopyWriting">
                    朋友圈文案生成器🔥
                    <p  >输入关键词，生成吸引人的朋友圈文案。</p>
                </div>
                <div    class="block" @click="gotoRestaurantReview">
                    餐厅点评小助手
                    <p  >提供关键词，一键生成餐厅评价。</p>
                </div>
                <div    class="block" @click="gotoProductReview">
                    商品点评小助手
                    <p  >输入商品信息，生成全面的商品评价。</p>
                </div>
                <div    class="block" @click="gotoDreamInterpretation">
                    解梦小助手
                    <p  >描述你的梦境，小助手将为你作出解析。</p>
                </div>
                <div    class="block" @click="gotoHolidayWishes">
                    节日祝福
                    <p  >输入节日名称，写出贴心的节日祝福。</p>
                </div>
                <div    class="block" @click="gotoWorkplaceTroubles">
                    职场开解小树洞
                    <p  >说出职场困扰，让我们为你出谋划策。</p>
                </div>
                <div    class="block" @click="gotoWikiKnowledge">
                    知识小百科
                    <p  >请输入不懂的问题或概念，小助手为你科普。</p>
                </div>
            </div>
            <div
               
              style="
                 
                color: black;
                font-size: 16px;
                font-weight: 600;
                text-align: center
              "
            >
              娱乐
            </div>
            <div   class="wrapper">
              <div    class="block" @click="gotoPraisingOthers">
                夸夸小助手
                <p  >描述想夸的人，我们来帮你夸。</p>
              </div>
              <div    class="block" @click="gotoReplyLeader">
                回复领导小助手
                <p  >
                  输入需要回复给领导的内容，小助手会改成委婉且职业化的版本，帮你拒绝职场PUA。
                </p>
              </div>
              <div    class="block" @click="gotoZhihuStyle">
                知乎风格问答器
                <p  >小助手用知乎文体回答你的问题。</p>
              </div>
              <div    class="block" @click="gotoEmoJi">
                emoji翻译小助手
                <p  >输入文字，转化成有趣的emoji。</p>
              </div>
              <div    class="block" @click="gotoPassBuck">
                甩锅小助手🔥
                <p  >描述需要甩锅的事件，生成机智的甩锅话。</p>
              </div>
              <div    class="block" @click="gotoComfortGirlfriend">
                哄女友小助手🔥
                <p  >
                  告诉小助手女友生气的原因，生成一段温馨的道歉话。
                </p>
              </div>
              <div    class="block" @click="gotoHighEQ">
                高情商回复小助手🔥
                <p  >
                  输入收到的不好回答的问题，小助手为你生成一段高情商的回复。
                </p>
              </div>
              <div    class="block" @click="gotoWinQuarrels">
                吵架小助手
                <p  >
                  输入需要回应的问题，小助手帮你吵赢这场架。
                </p>
              </div>
            </div>
            <div
               
              style="
                 
                color: black;
                font-size: 16px;
                font-weight: 600;
                text-align: center
              "
            >
              图片生成器
            </div>
            <div   class="wrapper">
              <div    class="block" @click="gotoImageGeneration">
                自由创作
                <p  >
                  输入创作主题，小助手和你一起画出美丽的画作。如：画一只狗。
                </p>
              </div>
            </div>
            <div
               
              style="
                 
                color: black;
                font-size: 16px;
                font-weight: 600;
                text-align: center
              "
            >
              以上只是部分内容正在更新中~欢迎挖掘更多玩法
            </div>
            <div   class="wrapper">
              <div    class="block" @click="cat" >
                更多玩法：
                <p  >上百种提示词点我查看</p>
              </div>
            </div>
          </div>
          <div   class="footer">
            <div   class="footer-content">
               AI 未来已来！会用AI让你领先对手一步！🧐否则你将被领先！
            </div>
            <div   class="footer-content">
              Al不会让你失去工作,能淘汰的只有没掌握Al的人.
            </div>
            <div   class="footer-content">
              加入用户交流群，了解或分享更多玩法 QQ交流群：602354227
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  el: "#app",
  data() {
    return {};
  },
  methods: {
    goto(){
          this.$router.push('/GptApp')
           location.reload();      
    },
    gotoWeekReprot(){
      this.$router.push('/tool/WeeklyReport')
    },
    gotoDailyReport(){
      this.$router.push('/tool/DailyReport')
    },
    gotoWriteProgramme(){
      this.$router.push('/tool/WriteProgramme')
    },
    gotoWriteEmail(){
      this.$router.push('/tool/WriteEmail')
    },
    gotoReadingAnalysis(){
      this.$router.push('/tool/ReadingAnalysis')
    },
    gotoPowerPoint(){
      this.$router.push('/tool/PowerPoint')
    },
    gotoOptimizeResume(){
      this.$router.push('/tool/OptimizeResume')
    },
    gotoCodeOptimization(){
      this.$router.push('/tool/CodeOptimization')
    },
    gotoSolidityDetection(){
      this.$router.push('/tool/SolidityDetection')
    },
    gotoCodeWriting(){
      this.$router.push('/tool/CodeWriting')
    },
    gotoEnglishWriting(){
      this.$router.push('/tool/EnglishWriting')
    },
    gotoMermaidFlowchart(){
      this.$router.push('/tool/MermaidFlowchart')
    },
    gotoSWOT(){
      this.$router.push('/tool/SWOT')
    },
    gotoOKR(){
      this.$router.push('/tool/OKR')
    },
    gotoSumUp(){
      this.$router.push('/tool/SumUp')
    },
    gotoShortVideo(){
      this.$router.push('/tool/ShortVideo')
    },
    gotoRedStyle(){
      this.$router.push('/tool/RedStyle')
    },
    gotoRedTitle(){
      this.$router.push('/tool/RedTitle')
    },
    gotoWriteArticles(){
      this.$router.push('/tool/WriteArticles')
    },
    gotoMakeTitle(){
      this.$router.push('/tool/MakeTitle')
    },
    gotoArticleSummary(){
      this.$router.push('/tool/ArticleSummary')
    },
    gotoTarotPrediction(){
      this.$router.push('/tool/TarotPrediction')
    },
    gotoCopyWriting(){
      this.$router.push('/tool/CopyWriting')
    },
    gotoRestaurantReview(){
      this.$router.push('/tool/RestaurantReview')
    },
    gotoProductReview(){
      this.$router.push('/tool/ProductReview')
    },
    gotoDreamInterpretation(){
      this.$router.push('/tool/DreamInterpretation')
    },
    gotoHolidayWishes(){
      this.$router.push('/tool/HolidayWishes')
    },
    gotoWorkplaceTroubles(){
      this.$router.push('/tool/WorkplaceTroubles')
    },
    gotoWikiKnowledge(){
      this.$router.push('/tool/WikiKnowledge')
    },
    gotoPraisingOthers(){
      this.$router.push('/tool/PraisingOthers')
    },
    gotoReplyLeader(){
      this.$router.push('/tool/ReplyLeader')
    },
    gotoZhihuStyle(){
      this.$router.push('/tool/ZhihuStyle')
    },
    gotoEmoJi(){
      this.$router.push('/tool/EmoJi')
    },
    gotoPassBuck(){
      this.$router.push('/tool/PassBuck')
    },
    gotoComfortGirlfriend(){
      this.$router.push('/tool/ComfortGirlfriend')
    },
    gotoHighEQ(){
      this.$router.push('/tool/HighEQ')
    },
    gotoWinQuarrels(){
      this.$router.push('/tool/WinQuarrels')
    },gotoImageGeneration(){
      this.$router.push('/tool/ImageGeneration')
    },
    cat(){
          window.location.href = "https://day.nb.sb/archives/432.html";
            this.$notify({
          title: 'ok~',
          message: '正在为您查看中~',
          type: 'success'
        });
    }
  },
  created() {
    //  this.$notify({
    //       title: '欢迎来到AI的世界😊~',
    //       message: 'chatgpt免费使用🥳~~',
    //       type: 'success'
    //     });
  },
};
</script>

<style scoped>

.wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

}
.block{
    border-radius: 5px;
    position: relative;
      box-shadow: 0 6px 24px 0 rgba(0,0,0,.05), 0 0 0 1px rgba(0,0,0,.08);
    background: #eee;

    font-size: 15px;
    margin: 5px;
    padding: 10px;
    max-width: 300px;
    min-width: 130px;
    height: 120px;
    color: #000;


    -webkit-transition: .3s;
    transition: .3s;
}

.block:hover{

  background: #eee;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
    list-style-type: none;
    display: inline-block;
   transform: translate(-5px, -5px);
    cursor: pointer;
   
}
.footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 30px;
}
.footer-content {
    color: #9e9b9b;
    font-size: 12px;
}
 .block p{
         font-size: 12px;
         font-weight: 300;
    }
     .gptapp {
      float: right;
      font-size: 13px;
      background-color: #64dbff;
      color: #fff;
      padding: 5px;
      border-radius: 8px;
      margin-top: -41px;
      margin-right: 50px;
    }
    .gptapp:hover {
      background-color: #529dff;
    }


@media only screen and (min-device-width: 760px){
    h1 {
      width: 90%;
    }
   
    .block {
        width: 30%;
    }
    .block {
        border-radius: 5px;
        box-shadow: 0 6px 24px 0 rgba(0,0,0,.05), 0 0 0 1px rgba(0,0,0,.08);
       
        font-weight: 600;
        background-color: #fff;
        margin: 8px;
        padding: 10px;
       width: 30%;
        height: 100px;
        color: #000;
    }
    .block p{
         font-size: 12px;
    }
}
@media only screen and (max-device-width: 760px){
    .block {
        width: 30%;
        height: 100px;
    }

    .block p{
         font-size: 12px;
    }
   
}
</style>
