<template>
  <div>
    <div>
      <h2>概览</h2>
      用户总数：{{ userlist[userlist.length - 1].id }}
    </div>
    <div>
      <h2>用户管理</h2>
      <div>
        <el-form :model="formData" ref="form" label-width="80px">
          <el-row>
            <el-col :span="8">
              <el-form-item label="搜索用户">
                <el-input v-model="keyword" placeholder="输入名字"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-table :data="filteredUserList" style="width: 100%;">
          <el-table-column prop="id" label="UID"></el-table-column>
          <el-table-column prop="username" label="用户名"></el-table-column>
          <el-table-column prop="email" label="邮箱"></el-table-column>
          <el-table-column prop="balance" label="余额"></el-table-column>
          <el-table-column prop="number" label="Token"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div>
                <el-button type="danger" plain @click="change_user_password(scope.row)">
                  修改密码
                </el-button>
                <el-button type="success" plain @click="change_user_number(scope.row)">
                  修改剩余token
                </el-button>
                <el-button type="warning" plain @click="change_user_balance(scope.row)">
                  修改余额
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40, 100]"
          :page-size="pageSize"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          style="margin-top: 20px;"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
import { GPTAPI } from '@/config';
const token = localStorage.getItem('token');
const config = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': ` ${token}`,
    'X-Requested-With': 'XMLHttpRequest'
  }
};

export default {

  data() {
    return {
      formData: {},
      keyword: '',
      userlist: [],
      currentPage: 1,
      pageSize: 10,
    };
  },
  computed: {
    filteredUserList() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.userlist.filter((user) => {
        return user.username.includes(this.keyword);
      }).slice(start, end);
    },
    total() {
      return this.userlist.filter((user) => {
        return user.username.includes(this.keyword);
      }).length;
    },
  },
  mounted() {
    this.fetchUserData();
  },
  methods: {
    fetchUserData() {
      const data = {};
      axios.post(GPTAPI + '/get_user_list', data, config)
        .then(response => {
          this.userlist = response.data["user_list"];
        })
        .catch(error => {
          console.log(error);
        });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.currentPage = 1;
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.fetchUserData();
    },
     change_user_number(i) {
                this.change_username = i.username
                // console.log(i.username)
                const inputPrice = prompt("修改token为：");
                if (inputPrice === null || isNaN(parseFloat(inputPrice)) || parseFloat(inputPrice) <= 0) {
                    alert("请输入有效的且大于0的数字");
                    return;
                }
                this.token_number = parseFloat(inputPrice);
                const data = {
                    username: this.change_username,
                    token_number: this.token_number
                }
                axios.post(GPTAPI + '/change_user_number', data, config)
                    .then(response => {            
                        this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                        location.reload();
                        console.log(response.data)
                        })
                    .catch(error => {
                        console.log(error);
                    })
            },
            change_user_balance(i) {
                this.change_username = i.username
                const inputPrice = prompt("修改用户剩余金额为：");
                if (inputPrice === null || isNaN(parseFloat(inputPrice)) || parseFloat(inputPrice) <= 0) {
                    alert("请输入有效的且大于0的数字");
                    return;
                }
                this.balance = parseFloat(inputPrice);
                const data = {
                    username: this.change_username,
                    balance: this.balance
                }
                axios.post(GPTAPI + '/change_user_balance', data, config)
                    .then(response => {
                       this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                        location.reload();
                        console.log(response.data)
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            change_user_password(i) {
                this.change_username = i.username
                const inputPrice = prompt("修改用户密码为：");
                if (inputPrice === null || inputPrice === "") {
                     this.$message({
                    message: '请输入有效的密码',
                    type: 'error'
                });
                    return;
                }
                this.new_password = inputPrice;
               
                const data = {
                    username: this.change_username,
                    new_password: this.new_password
                }
                axios.post(GPTAPI + '/change_user_password', data, config)
                    .then(response => {
                         this.$message({
                        message: '修改成功',
                        type: 'success'
                    });
                        location.reload();
                        console.log(response.data)
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
  },
};
</script>

<style scoped>
* {
  font-size: 16px;
}

</style>
<style>
el-button{
  width: 80px !important;
}</style>