import StartLogin from "@/components/StartLogin";
import GptApp from "@/components/GptApp";
import StartRegister from "@/components/StartRegister";
import AdminUser from "@/components/AdminUser";
import UserInformation from "@/components/UserInformation";
import IndexShow from "@/components/IndexShow";
import WeeklyReport from "@/components/tool/WeeklyReport";
import TestCode from "@/components/TestCode";
import MermaidFlowchart from "@/components/tool/MermaidFlowchart";
import DailyReport from "@/components/tool/DailyReport";
import WriteProgramme from "@/components/tool/WriteProgramme";
import WriteEmail from "@/components/tool/WriteEmail";
import ReadingAnalysis from "@/components/tool/ReadingAnalysis";
import PowerPoint from "@/components/tool/PowerPoint";
import OptimizeResume from "@/components/tool/OptimizeResume";
import CodeOptimization from "@/components/tool/CodeOptimization";
import CodeWriting from "@/components/tool/CodeWriting";
import EnglishWriting from "@/components/tool/EnglishWriting";
import SWOT from "@/components/tool/SWOT";
import OKR from "@/components/tool/OKR";
import SumUp from "@/components/tool/SumUp";
import ShortVideo from "@/components/tool/ShortVideo";
import RedStyle from "@/components/tool/RedStyle";
import RedTitle from "@/components/tool/RedTitle";
import WriteArticles from "@/components/tool/WriteArticles";
import MakeTitle from "@/components/tool/MakeTitle";
import ArticleSummary from "@/components/tool/ArticleSummary";
import TarotPrediction from "@/components/tool/TarotPrediction";
import CopyWriting from "@/components/tool/CopyWriting";
import RestaurantReview from "@/components/tool/RestaurantReview";
import ProductReview from "@/components/tool/ProductReview";
import DreamInterpretation from "@/components/tool/DreamInterpretation";
import HolidayWishes from "@/components/tool/HolidayWishes";
import WorkplaceTroubles from "@/components/tool/WorkplaceTroubles";
import WikiKnowledge from "@/components/tool/WikiKnowledge";
import PraisingOthers from "@/components/tool/PraisingOthers";
import ReplyLeader from "@/components/tool/ReplyLeader";
import ZhihuStyle from "@/components/tool/ZhihuStyle";
import EmoJi from "@/components/tool/EmoJi";
import PassBuck from "@/components/tool/PassBuck";
import ComfortGirlfriend from "@/components/tool/ComfortGirlfriend";
import HighEQ from "@/components/tool/HighEQ";
import WinQuarrels from "@/components/tool/WinQuarrels";
import SolidityDetection from "@/components/tool/SolidityDetection";
import ImageGeneration from "@/components/tool/ImageGeneration";

export default [
  { path: '/', redirect: '/IndexShow' },
  { path: '/StartLogin', component: StartLogin },
  { path: '/GptApp', component: GptApp },
  { path: '/StartRegister', component: StartRegister },
  { path: '/AdminUser', component: AdminUser },
  { path: '/UserInformation', component: UserInformation },
  { path: '/IndexShow', component: IndexShow },
  { path: '/tool/WeeklyReport', component: WeeklyReport },
  { path: '/TestCode', component: TestCode },
  { path: '/tool/MermaidFlowchart', component: MermaidFlowchart },
  { path: '/tool/DailyReport', component: DailyReport },
  { path: '/tool/WriteProgramme', component: WriteProgramme },
  { path: '/tool/WriteEmail', component: WriteEmail },
  { path: '/tool/ReadingAnalysis', component: ReadingAnalysis },
  { path: '/tool/PowerPoint', component: PowerPoint },
  { path: '/tool/OptimizeResume', component: OptimizeResume },
  { path: '/tool/CodeOptimization', component: CodeOptimization },
  { path: '/tool/CodeWriting', component: CodeWriting },
  { path: '/tool/EnglishWriting', component: EnglishWriting },
  { path: '/tool/SWOT', component: SWOT },
  { path: '/tool/OKR', component: OKR },
  { path: '/tool/SumUp', component: SumUp },
  { path: '/tool/ShortVideo', component: ShortVideo },
  { path: '/tool/RedStyle', component: RedStyle },
  { path: '/tool/RedTitle', component: RedTitle },
  { path: '/tool/WriteArticles', component: WriteArticles },
  { path: '/tool/MakeTitle', component: MakeTitle },
  { path: '/tool/ArticleSummary', component: ArticleSummary },
  { path: '/tool/TarotPrediction', component: TarotPrediction },
  { path: '/tool/CopyWriting', component: CopyWriting },
  { path: '/tool/RestaurantReview', component: RestaurantReview },
  { path: '/tool/ProductReview', component: ProductReview },
  { path: '/tool/DreamInterpretation', component: DreamInterpretation },
  { path: '/tool/HolidayWishes', component: HolidayWishes },
  { path: '/tool/WorkplaceTroubles', component: WorkplaceTroubles },
  { path: '/tool/WikiKnowledge', component: WikiKnowledge },
  { path: '/tool/PraisingOthers', component: PraisingOthers },
  { path: '/tool/ReplyLeader', component: ReplyLeader },
  { path: '/tool/ZhihuStyle', component: ZhihuStyle },
  { path: '/tool/EmoJi', component: EmoJi },
  { path: '/tool/PassBuck', component: PassBuck },
  { path: '/tool/ComfortGirlfriend', component: ComfortGirlfriend },
  { path: '/tool/HighEQ', component: HighEQ },
  { path: '/tool/WinQuarrels', component: WinQuarrels },
  { path: '/tool/SolidityDetection', component: SolidityDetection },
  { path: '/tool/ImageGeneration', component: ImageGeneration },
]
