import { render, staticRenderFns } from "./WinQuarrels.vue?vue&type=template&id=44c4790a&scoped=true&"
import script from "./WinQuarrels.vue?vue&type=script&lang=js&"
export * from "./WinQuarrels.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44c4790a",
  null
  
)

export default component.exports