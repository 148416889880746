<template>
  <div class="TestCode">
    <hello 
    :inputContent="inputContent"
    :title="title"
    :title02="title02"
    :systemPrompt="systemPrompt"
    :name_model="name_model"
    @input-change="handleInputChange"
    ></hello>
</div>
</template>
<script>
import hello from "@/components/tool/helloWorld.vue";
export default {
    components:{
            hello
        },
  data() {
    return {
        input: '',
        title: "mermaid",
        name_model: "mermaid",
        title02:"输入went",
        inputContent: "示例：",
        systemPrompt:"chatgpt"
    };
  },
  methods: {
    // handleInputChange(val) {
    //   this.input = val;
    //   alert(this.input)
    // }
  }
  
  
};
</script>

<style scoped>
</style>