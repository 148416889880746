<template>
    <div class="ReplyLeader">
        <hello 
        :inputContent="inputContent"
        :title="title"
        :title02="title02"
        :systemPrompt="systemPrompt"
        :name_model="name_model"
        @input-change="handleInputChange"
        ></hello>
    </div>
</template>
<script>
import hello from "@/components/tool/helloWorld.vue";
export default {
    components:{
            hello
        },
data() {
    return {
        input: '',
        title: "回复领导小助手",
        name_model: "回复领导",
        title02:"输入需要回复给领导的内容，小助手会改成委婉且职业化的版本，帮你拒绝职场PUA。",
        inputContent: "示例：小赵，加班完成下项目，下个月绩效最高的一定是你",
        systemPrompt:"ReplyLeader"  //对话模组
    };
},
methods: {
    handleInputChange(val) {
    this.input = val;
    alert(this.input)
    }
}


};
</script>

<style scoped>
</style>