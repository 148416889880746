<template>
    <div class="ImageGeneration">         
        <div class="header">
            <h1>ChatGPT 免费使用🥳</h1>
            <div class="BackIndex">
                <span
                class="el-icon-s-grid"
                @click="getback()"
                title="返回列表"
                ></span>
                <span
                class="el-icon-shopping-bag-1"
                @click="gotoshop()"
                title="前往商店"
                ></span>
            </div>
            <div class="shop">
                <a href="https://tags.novelai.dev/"><span class="el-icon-shopping-cart-2">Danbooru 标签商店</span></a>
            </div>
        </div>
        <div class="content">
            <div class="content-mid">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="精致绘图" name="second">
                    <div class="input-text">
                        <el-input v-model="input1" type="textarea" :autosize="{ minRows: 2, maxRows: 4}" data-testid="textbox1" class="gr-box" placeholder="请输入简单的描述，AI将智能输出绘画内容" rows="3" style=" height: 70px;"></el-input>
                        <el-input v-model="input2" type="textarea" :autosize="{ minRows: 2, maxRows: 4}" data-testid="textbox2" class="gr-box" placeholder="请输入不想要的描述，AI绘图将不会出现相关内容" rows="2" style=" height: 63px;"></el-input>
                        <div class="input-button" id="txt2img_generate_box">
                            <div class="button-container">
                                <button class="generate-btn">生成</button>
                                <div class="btn-group">
                                    <button class="terminate-btn">终止</button>
                                    <button class="skip-btn">跳过</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="settings">
                        <span>参数设置</span>
                        <div class="imagesetting">
                            <div class="block1">
                                <span class="demonstration">图片高度：</span>
                                <el-slider
                                v-model="value"
                                :max="1024"
                                :step="256"
                                show-stops>
                                </el-slider>
                            </div>
                            &nbsp;
                            <div class="block2">
                                <span class="demonstration">图片宽度：</span>
                                <el-slider
                                v-model="value1"
                                :max="1024"
                                :step="256"
                                show-stops>
                                </el-slider>
                            </div>
                            &nbsp;
                            <div class="block3">
                                <span>提示词相关性：</span>
                                <el-slider
                                v-model="value2"
                                :max="30"
                                show-input>
                                </el-slider>
                            </div>
                            &nbsp;
                            <div class="quantity-span"><span>图片数量：</span></div>
                            <div class="quantity-buttons">
                                <el-button type="button" class="quantity-button" data-quantity="1">1张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="2">2张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="3">3张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="4">4张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="5">5张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="6">6张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="7">7张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="8">8张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="9">9张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="10">10张</el-button>
                            </div>
                        </div>
                        &nbsp;
                        <div class="seed">
                            <div class="seedspan" style="flex-direction: inherit;">
                                <div id="txt2img_seed" class="gr-box">
                                    <label class="blockseed">
                                        <span class="" title="一个固定随机数生成器输出的值 - 以相同参数和随机种子生成的图像会得到相同的结果🎲 将随机种子设置为-1，则每次都会使用一个新的随机数♻️ 重用上一次使用的随机种子，如果想要固定输出结果就会很有用">随机种子(seed)</span> 
                                        <input type="number" class="gr-box">
                                    </label>
                                </div>
                            </div>
                            <button class="gr-button" title="将随机种子设置为 -1，则每次都会使用一个新的随机数">🎲️</button>
                            <button class="gr-button" title="重用上一次使用的随机种子，如果想要固定结果就会很有用">♻️</button>
                        </div>
                        &nbsp;
                        <hr>
                        <div class="information">
                            <h3>修饰词参考</h3>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;修饰词参考您可参考或选用下列各类饰词丰富您的输入文本，尝试生成更加多样的图像，更多修饰词可参考 Danbooru标签商店 或 自由输入 探索大模型作国更多未知能力！！</span>
                            <h3>图像类型</h3>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;古风、二次元、写实照片、油画、水彩画、油墨画、黑白雕版画、雕塑、3D模型、手绘草图、炭笔画,更多类型在 Danbooru标签商店 等你！！</span>
                        </div>
                    </div>
                    <div class="images">
                        <el-tabs type="border-card">
                            <el-tab-pane>
                                <span slot="label"><i class="el-icon-s-custom"></i> 我的生成</span>
                            </el-tab-pane>
                            <el-tab-pane>
                                <span slot="label"><i class="el-icon-picture"></i> 公共生成</span>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="图生图" name="third" class="third">
                    <div class="input-text">
                        <el-input v-model="input1" type="textarea" :autosize="{ minRows: 2, maxRows: 4}" data-testid="textbox1" class="gr-box" placeholder="请输入简单的描述，AI将智能输出绘画内容" rows="3" style=" height: 70px;"></el-input>
                        <el-input v-model="input2" type="textarea" :autosize="{ minRows: 2, maxRows: 4}" data-testid="textbox2" class="gr-box" placeholder="请输入不想要的描述，AI绘图将不会出现相关内容" rows="2" style=" height: 63px;"></el-input>
                        <div class="input-button" id="txt2img_generate_box">
                            <div class="button-container">
                                <button class="generate-btn1">生成</button>
                                <div class="btn-group">
                                    <button class="terminate-btn1">终止</button>
                                    <button class="skip-btn1">跳过</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="settings">
                        <div id="app">
                            <el-upload
                            class="upload-demo"
                            drag
                            action="https://jsonplaceholder.typicode.com/posts/"
                            multiple>
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                            <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                            </el-upload>
                        </div>
                        <span>参数设置</span>
                        <div class="imagesetting">
                            <div class="block1">
                                <span class="demonstration">图片高度：</span>
                                <el-slider
                                v-model="value"
                                :max="1024"
                                :step="256"
                                show-stops>
                                </el-slider>
                            </div>
                            &nbsp;
                            <div class="block2">
                                <span class="demonstration">图片宽度：</span>
                                <el-slider
                                v-model="value1"
                                :max="1024"
                                :step="256"
                                show-stops>
                                </el-slider>
                            </div>
                            &nbsp;
                            <div class="block3">
                                <span>提示词相关性：</span>
                                <el-slider
                                v-model="value2"
                                :max="30"
                                show-input>
                                </el-slider>
                            </div>
                            &nbsp;
                            <div class="quantity-span"><span>图片数量：</span></div>
                            <div class="quantity-buttons">
                                <el-button type="button" class="quantity-button" data-quantity="1">1张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="2">2张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="3">3张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="4">4张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="5">5张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="6">6张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="7">7张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="8">8张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="9">9张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="10">10张</el-button>
                            </div>
                        </div>
                        &nbsp;
                        <div class="seed">
                            <div class="seedspan" style="flex-direction: inherit;">
                                <div id="txt2img_seed" class="gr-box">
                                    <label class="blockseed">
                                        <span class="" title="一个固定随机数生成器输出的值 - 以相同参数和随机种子生成的图像会得到相同的结果🎲 将随机种子设置为-1，则每次都会使用一个新的随机数♻️ 重用上一次使用的随机种子，如果想要固定输出结果就会很有用">随机种子(seed)</span> 
                                        <input type="number" class="gr-box">
                                    </label>
                                </div>
                            </div>
                            <button class="gr-button" title="将随机种子设置为 -1，则每次都会使用一个新的随机数">🎲️</button>
                            <button class="gr-button" title="重用上一次使用的随机种子，如果想要固定结果就会很有用">♻️</button>
                        </div>
                        &nbsp;
                        <hr>
                        <div class="information">
                            <h3>修饰词参考</h3>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;修饰词参考您可参考或选用下列各类饰词丰富您的输入文本，尝试生成更加多样的图像，更多修饰词可参考 Danbooru标签商店 或 自由输入 探索大模型作国更多未知能力！！</span>
                            <h3>图像类型</h3>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;古风、二次元、写实照片、油画、水彩画、油墨画、黑白雕版画、雕塑、3D模型、手绘草图、炭笔画,更多类型在 Danbooru标签商店 等你！！</span>
                        </div>
                    </div>
                    <div class="images">
                        <el-tabs type="border-card">
                            <el-tab-pane>
                                <span slot="label"><i class="el-icon-s-custom"></i> 我的生成</span>
                            </el-tab-pane>
                            <el-tab-pane>
                                <span slot="label"><i class="el-icon-picture"></i> 公共生成</span>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="图像高清处理" name="fourth" class="fourth">
                    <div class="input-text">
                        <el-input v-model="input1" type="textarea" :autosize="{ minRows: 2, maxRows: 4}" data-testid="textbox1" class="gr-box" placeholder="请输入简单的描述，AI将智能输出绘画内容" rows="3" style=" height: 70px;"></el-input>
                        <el-input v-model="input2" type="textarea" :autosize="{ minRows: 2, maxRows: 4}" data-testid="textbox2" class="gr-box" placeholder="请输入不想要的描述，AI绘图将不会出现相关内容" rows="2" style=" height: 63px;"></el-input>
                        <div class="input-button" id="txt2img_generate_box">
                            <div class="button-container">
                                <button class="generate-btn2">生成</button>
                                <div class="btn-group">
                                    <button class="terminate-btn2">终止</button>
                                    <button class="skip-btn2">跳过</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="settings">
                        <div id="app">
                            <el-upload
                            class="upload-demo"
                            drag
                            action="https://jsonplaceholder.typicode.com/posts/"
                            multiple>
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                            <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
                            </el-upload>
                        </div>
                        <span>参数设置</span>
                        <div class="imagesetting">
                            <div class="block4" style="width: 45%;">
                                <span class="demonstration">高清修复采样次数：</span>
                                <el-slider
                                v-model="value3"
                                :max="150"
                                :step="1">
                                </el-slider>
                            </div>
                            &nbsp;
                            <div class="block3" style="width: 45%; float: right; margin-top: -38px;">
                                <span class="demonstration">重绘幅度(Denoising)：</span>
                                <el-slider
                                v-model="value4"
                                :max="1"
                                :step="0.01">
                                </el-slider>
                            </div>
                            &nbsp;
                            <div class="block1">
                                <span class="demonstration">图片高度：</span>
                                <el-slider
                                v-model="value"
                                :max="1024"
                                :step="256"
                                show-stops>
                                </el-slider>
                            </div>
                            &nbsp;
                            <div class="block2">
                                <span class="demonstration">图片宽度：</span>
                                <el-slider
                                v-model="value1"
                                :max="1024"
                                :step="256"
                                show-stops>
                                </el-slider>
                            </div>
                            &nbsp;
                            <div class="block3">
                                <span>提示词相关性：</span>
                                <el-slider
                                v-model="value2"
                                :max="30"
                                show-input>
                                </el-slider>
                            </div>
                            &nbsp;
                            <div class="quantity-span"><span>图片数量：</span></div>
                            <div class="quantity-buttons">
                                <el-button type="button" class="quantity-button" data-quantity="1">1张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="2">2张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="3">3张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="4">4张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="5">5张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="6">6张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="7">7张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="8">8张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="9">9张</el-button>
                                <el-button type="button" class="quantity-button" data-quantity="10">10张</el-button>
                            </div>
                        </div>
                        &nbsp;
                        <div class="seed">
                            <div class="seedspan" style="flex-direction: inherit;">
                                <div id="txt2img_seed" class="gr-box">
                                    <label class="blockseed">
                                        <span class="" title="一个固定随机数生成器输出的值 - 以相同参数和随机种子生成的图像会得到相同的结果🎲 将随机种子设置为-1，则每次都会使用一个新的随机数♻️ 重用上一次使用的随机种子，如果想要固定输出结果就会很有用">随机种子(seed)</span> 
                                        <input type="number" class="gr-box">
                                    </label>
                                </div>
                            </div>
                            <button class="gr-button" title="将随机种子设置为 -1，则每次都会使用一个新的随机数">🎲️</button>
                            <button class="gr-button" title="重用上一次使用的随机种子，如果想要固定结果就会很有用">♻️</button>
                        </div>
                        &nbsp;
                        <hr>
                        <div class="information">
                            <h3>修饰词参考</h3>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;修饰词参考您可参考或选用下列各类饰词丰富您的输入文本，尝试生成更加多样的图像，更多修饰词可参考 Danbooru标签商店 或 自由输入 探索大模型作国更多未知能力！！</span>
                            <h3>图像类型</h3>
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;古风、二次元、写实照片、油画、水彩画、油墨画、黑白雕版画、雕塑、3D模型、手绘草图、炭笔画,更多类型在 Danbooru标签商店 等你！！</span>
                        </div>
                    </div>
                    <div class="images">
                        <el-tabs type="border-card">
                            <el-tab-pane>
                                <span slot="label"><i class="el-icon-s-custom"></i> 我的生成</span>
                            </el-tab-pane>
                            <el-tab-pane>
                                <span slot="label"><i class="el-icon-picture"></i> 公共生成</span>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </el-tab-pane>
            </el-tabs>
            </div> 
        </div>
    </div>
</template>

<script>
import { GPTAPI } from "@/config";
const axios = require("axios");
export default {
    components:{
        },
data() {
    return {
        input1: '',
        input2: '',
        input3: '',
        systemPrompt:"ImageGeneration" , //对话模组
        activeName: 'second',
        value: 256,
        value1: 256,
        value2: 7,
        value3: 0,
        value4: 0.7,
    };
},
methods: {
    handleClick(tab, event) {
        console.log(tab, event);
    },
    handleInputChange(val) {
        this.input = val;
        alert(this.input)
    },
    getback() {
        this.$router.push("/IndexShow");
        location.reload()
    },
    gotoshop() {
        window.open('https://tags.novelai.dev/')
    },
    makeimag() {

    },
    async sendMessage() {
      const h = this.$createElement;
      if (this.input.trim() === "") {
        const errorMessage = document.createElement("div");
        this.no_Input();
        document.body.appendChild(errorMessage);
        return false;
      }
      if (parseInt(this.number) < 100 && parseInt(this.number) >= 0) {
        this.button_loding = !this.button_loding;
        this.button_loding01 = !this.button_loding01;
        this.$notify({
          title: "提示：",
          message: h(
            "i",
            { style: "color: teal" },
            "您的剩余token小于100，但请求可能大于100个token，为了不影响您的正常体验，请进行兑换token后再进行提问，加Q群602354227免费领取10万token"
          ),
        });
        return "";
      } else if (parseInt(this.number) < 0) {
       
        this.$notify({
          title: "提示：",
          message: h(
            "i",
            { style: "color: teal" },
            "您的剩余token小于0，为了不影响您的正常体验，请进行兑换token后再进行提问，加Q群602354227免费领取10万token"
          ),
        });
        return "";
      } else {
        let token_ = localStorage.getItem('token')
            if (!token_) {
            this.$message({
                message: '并未登陆，正在为您跳转登陆~',
                type: 'error'
            });
            setTimeout(() => {
                this.$router.push("/StartLogin");
                location.reload();
            }, 1000);
            return "";
            }
        this.$notify({
          title: "成功",
          showClose: true,
          message: "发送成功，回复正在书写中~",
          type: "success",
        });
         this.button_loding = !this.button_loding;
        this.button_loding01 = !this.button_loding01;
        const token = localStorage.getItem("token");
        const config = {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: ` ${token}`,
            "X-Requested-With": "XMLHttpRequest",
          },
        };
        const data = {
          user_session_id: this.name_model,
          mess: this.input,
          system_mold: this.systemPrompt,
          continue:false,
          stream_mes:true
        };
        const response = await fetch(GPTAPI + "/testgpt", {
          method: "POST",
          body: JSON.stringify(data),
          headers: config.headers,
        });
        const reader = response.body.getReader();
        const decoder = new TextDecoder("utf-8");
        let result;
         this.response = ""
        do {
          result = await reader.read();
          const chunk = decoder.decode(result.value || new Uint8Array(), {
            stream: !result.done,
          });
          if (chunk !== "assistant") {
            const newContent =this.response + chunk;
            this.response = newContent;

          }
        } while (!result.done);
        this.button_loding = !this.button_loding;
        this.button_loding01 = !this.button_loding01;
        this.remove_number_fun()
      }
      
    },
    remove_number_fun() {
      // 减去剩余number字数
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: ` ${token}`,
          "X-Requested-With": "XMLHttpRequest",
        },
      };
      const data2 = {
        user_session_id: this.name_model,
        continue:false
      };
      axios
        .post(GPTAPI + "/remove_number", data2, config)
        .then((response) => {
          // console.log(response.data)
          this.number = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    no_Input() {
      this.$message.error("未输入内容~");
    },
    now_error() {
      this.$message.error("正在生成中请稍等~");
    },
  },
mounted(){
    const generateBtn = document.querySelector('.generate-btn');
    const terminateBtn = document.querySelector('.terminate-btn');
    const skipBtn = document.querySelector(".skip-btn") 
    generateBtn.addEventListener('click', () => {
        alert("生成图片")
        generateBtn.classList.add('active');
    });

    terminateBtn.addEventListener('click', () => {
        alert("终止生成图片")
        generateBtn.classList.remove('active');
    });

    skipBtn.addEventListener('click', () => {
        alert("跳过生成图片")
        generateBtn.classList.remove('active');
    });

    const generateBtn1 = document.querySelector('.generate-btn1');
    const terminateBtn1 = document.querySelector('.terminate-btn1');
    const skipBtn1 = document.querySelector(".skip-btn1")

    generateBtn1.addEventListener('click', () => {
        alert("生成图片")
        generateBtn1.classList.add('active');
    });

    terminateBtn1.addEventListener('click', () => {
        alert("终止生成图片")
        generateBtn1.classList.remove('active');
    });

    skipBtn1.addEventListener('click', () => {
        alert("跳过生成图片")
        generateBtn1.classList.remove('active');
    });

    const generateBtn2 = document.querySelector('.generate-btn2');
    const terminateBtn2 = document.querySelector('.terminate-btn2');
    const skipBtn2 = document.querySelector(".skip-btn2")

    generateBtn2.addEventListener('click', () => {
        alert("生成图片")
        generateBtn2.classList.add('active');
    });

    terminateBtn2.addEventListener('click', () => {
        alert("终止生成图片")
        generateBtn2.classList.remove('active');
    });

    skipBtn2.addEventListener('click', () => {
        alert("跳过生成图片")
        generateBtn2.classList.remove('active');
    });
}
}
</script>

<style scoped>
.shop {
    float: right;
    font-size: 20px;
    color: #00b7fff1;
    margin-top: -30px;
    margin-right: 36%;
}
.shop:hover {
    color: rgb(147, 239, 255);
}
.content-mid {
  margin-top: 60px;
  margin-left: 20px;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding-left: 30px;
}
.header h1 {
  font-size: 20px;
}


.el-icon-s-grid {
    color: rgb(4, 4, 4);
    font-size: 20px;
    border: 0.8px rgb(181, 173, 173) solid;
    padding: 10px;
    border-radius: 10px;
    margin-left: 10px;
}
.el-icon-shopping-bag-1 {
    color: rgb(4, 4, 4);
    font-size: 20px;
    border: 0.8px rgb(181, 173, 173) solid;
    padding: 10px;
    border-radius: 10px;
    margin-left: 10px;
    display: none;
}
.el-icon-s-grid:hover {
    border: 0.8px rgba(29, 147, 171) solid;
    background-color: #ededed;
}
.el-icon-shopping-bag-1:hover {
    content: attr(title);
    border: 0.8px rgba(29, 147, 171) solid;
    background-color: #ededed;
    content: attr(title);
}
    
.BackIndex {
    float: right;
    font-size: 14px;
    color: #fff;
    padding: 8px 20px;
    border-radius: 8px;
    margin-top: -52px;
    margin-right: 55px;
}
.gr-box {
    border-radius: 20px !important;
    border-width: 1px !important;
    text-align: center;
    width: 82%;
}
.input-text {
    /* background-color: rgb(237, 255, 255); */
    border-radius: 5px;
    width: 98%;
}
.input-button {
    border-radius: 10px;
    border-color: #fff;
    width: 20%;
    height: 70px;
    margin: 20px;
    /* float: right; */
    align-items: center;
    margin-top: -75px;
    margin-left: 84%;
    
}
.button-container {
  position: relative;
}

.generate-btn {
  padding: 10px 20px;
  border: none;
  width: 250px;
  height: 90px;
  border-radius: 10px;
  background-color: #2ed5ff;
  color: #fff;
  transition: all 0.3s ease;
  margin-top: -50px;
}

.generate-btn:hover {
  background-color: #8cf2ff;
  cursor: pointer;
}

.btn-group {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

.terminate-btn,
.skip-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  width: 125px;
  height: 90px;
  background-color: #8b8b8b;
  color: #fff;
  transition: all 0.3s ease;
  margin-top: -35px;
}

.terminate-btn:hover,
.skip-btn:hover {
  background-color: #e53935;
  cursor: pointer;
}

.generate-btn.active {
  display: none;
}

.generate-btn.active + .btn-group {
  display: flex;
}

.terminate-btn {
  margin-right: 1px;
}

.generate-btn1 {
  padding: 10px 20px;
  border: none;
  width: 250px;
  height: 90px;
  border-radius: 10px;
  background-color: #2ed5ff;
  color: #fff;
  transition: all 0.3s ease;
  margin-top: -50px;
}

.generate-btn1:hover {
  background-color: #8cf2ff;
  cursor: pointer;
}


.terminate-btn1,
.skip-btn1 {
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  width: 125px;
  height: 90px;
  background-color: #8b8b8b;
  color: #fff;
  transition: all 0.3s ease;
  margin-top: -35px;
}

.terminate-btn1:hover,
.skip-btn1:hover {
  background-color: #e53935;
  cursor: pointer;
}

.generate-btn1.active {
  display: none;
}

.generate-btn1.active + .btn-group {
  display: flex;
}

.terminate-btn1 {
  margin-right: 1px;
}

.generate-btn2 {
  padding: 10px 20px;
  border: none;
  width: 250px;
  height: 90px;
  border-radius: 10px;
  background-color: #2ed5ff;
  color: #fff;
  transition: all 0.3s ease;
  margin-top: -50px;
}

.generate-btn2:hover {
  background-color: #8cf2ff;
  cursor: pointer;
}


.terminate-btn2,
.skip-btn2 {
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  width: 125px;
  height: 90px;
  background-color: #8b8b8b;
  color: #fff;
  transition: all 0.3s ease;
  margin-top: -35px;
}

.terminate-btn2:hover,
.skip-btn2:hover {
  background-color: #e53935;
  cursor: pointer;
}

.generate-btn2.active {
  display: none;
}

.generate-btn2.active + .btn-group {
  display: flex;
}

.terminate-btn2 {
  margin-right: 1px;
}
.settings {
    background-color: #e7faff;
    border-radius: 10px;
    width: 57%;
    height: 735px;
    margin-top: 10px;
}
.settings span {
    font-size: 20px;
    position: relative;
    float: left;
    left: 30px;
    top: 20px;
}

.imagesetting{
    width: 80%;
    float: left;
    margin-left: -50px;
    margin-top: 80px;
}

.imagesetting span {
    font-size: 15px;
    top: -10px;
}
.el-button {
    margin-left: 0px;
}
.quantity-buttons {
    flex-direction: row;
    flex-wrap: wrap;
    display:  flex;
    width:  70%;
    margin-left: 80px;
    align-items: center;
    margin-top: 20px;
}
.quantity-buttons span {
    position: relative;
    left: -20px;
    top: 0px;
    white-space: nowrap;
}


.quantity-button {
    background: rgba(191,196,208,.2);
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    margin-right: 10px;
    padding: 3px 20px;
    text-align: center;
    transition: all .16s;
    margin-bottom: 5px;
    height: 30px;
    width: 70px;
}

.quantity-button:last-child {
  margin-right: 0;
}

.quantity-button.active {
  background-color: #1c9cbb;
  color: #fff;
}

.quantity-button:hover {
  background-color: #abf5ff;
}

.quantity-button:active {
  background-color: #1c9cbb;
  color: #fff;
}
.seedspan span{
    font-size: 15px;
    margin-left: 30px;
}

.blockseed input{
    width: 85%;
    height: 50px;
    float: left;
    margin-top: 28px;
    margin-left: 80px;
}

.seed button {
    color: rgb(4, 4, 4);
    font-size: 20px;
    border: 0.8px rgb(181, 173, 173) solid;
    padding: 5px;
    border-radius: 10px;
    margin-right: 10px;
    margin-top: 380px;
    z-index: 0;
}


.information h3 {
    font-size: 20px;
    position: relative;
    float: left;
    left: 30px;
    top: 20px;
}

.information span {
    font-size: 15px;
    width: 95%;
}

.images {
    background-color: #daeaee;
    width: 40%;
    border-radius: 10px;
    height: 735px;
    float: right;
    margin-top: -735px;
    margin-right: 35px;
}
.third .settings{
    height: 945px;
}
.fourth .settings {
    height: 995px;
}
.third .images {
    height: 945px;
    margin-top: -945px;
}
.fourth .images {
    height: 995px;
    margin-top: -995px;
}
.upload-demo {
    width: 50%;
    margin-left: 30%;
}
.fourth .information h3 {
    width: 100%;
}
.fourth .seed button {
    margin-top: 435px;
}
@media (max-width: 1640px) {
    .generate-btn,.generate-btn1,.generate-btn2 {
        width: 200px;
    }
    .terminate-btn,.terminate-btn1,.terminate-btn2,.skip-btn,.skip-btn1,.skip-btn2 {
        width: 100px;
    }
    .settings {
        height: 820px;
    }
    .images {
        height: 820px;
        margin-top: -820px;
    }
    .third .settings {
        height: 1030px;
    }
    .third .images {
        height: 1030px;
        margin-top: -1030px;
    }

    .fourth .settings {
        height: 1060px;
    }
    .fourth .images {
        height: 1060px;
        margin-top: -1060px;
    }
}
@media (max-width: 1272px) {
    .seed button {
        margin-top: 435px;
    }
    .settings {
        height: 820px;
    }
    .images {
        height: 820px;
        margin-top: -820px;
    }
    .third .settings {
        height: 1030px;
    }
    .third .images {
        height: 1030px;
        margin-top: -1030px;
    }

    .fourth .settings {
        height: 1090px;
    }
    .fourth .images {
        height: 1090px;
        margin-top: -1090px;
    }
}
@media (max-width: 1165px) {
    .seed button{
        margin-top: 33px;
        float: left;
    }
    .generate-btn,.generate-btn1,.generate-btn2 {
        width: 130px;
    }
    .terminate-btn,.terminate-btn1,.terminate-btn2,.skip-btn,.skip-btn1,.skip-btn2 {
        width: 65px;
    }
    hr {
        display: none;
    }
    .information h3 {
        width: 20%;
    }
    .information span {
        width: 88%;
    }
    .quantity-buttons {
        display: block;
    }
    .shop {
        margin-right: 20%;
    }
    .upload-demo {
    width: 50%;
    margin-left: 10%;
    }
    .seed button {
        margin-right: 0px;
    }
    .fourth .seed button {
        margin-top: 35px;
    }
}
@media (max-width: 990px) {
    .seed button {
        width: 7%;
        margin-right: 0px;
    }
    .information h3 {
        width: 100%;
    }.information span {
        width: 90%;
    }
    .settings {
        height: 960px;
    }
    .third .settings {
        height: 1170px;
    }
    .images {
        margin-top: -960px;
        margin-right: 20px;
        height: 960px;
    }
    .third .images {
        margin-top: -1170px;
        margin-right: 20px;
        height: 1170px;
    }
    .blockseed input {
        width: 82%;
    }
    .fourth .settings {
        height: 1240px;
    }
    .fourth .images {
        margin-top: -1240px;
        /* margin-right: 20px; */
        height: 1240px;
    }
    .fourth .seed button {
        margin-right: 0px;
        width: 7%;
    }
    .generate-btn,.generate-btn1,.generate-btn2 {
        width: 130px;
    }
    .terminate-btn,.terminate-btn1,.terminate-btn2,.skip-btn,.skip-btn1,.skip-btn2 {
        width: 65px;
    }
}
@media (max-width: 768px) {
    .content {
        width: 90%;
    }
    .gr-box {
        width: 60%;
    }
    .input-button {
        width: 30%;
        margin-top: -80px;
    }
    .generate-btn,.generate-btn1,.generate-btn2 {
        width: 100px;
    }
    .terminate-btn,.terminate-btn1,.terminate-btn2,.skip-btn,.skip-btn1,.skip-btn2 {
        width: 50px;
    }
    .el-icon-shopping-cart-2 {
        display: none;
    }
    .el-icon-shopping-bag-1 {
        display: inline;
    }
    .input-button {
        margin-left: 65%;
    }
    .settings {
        width: 100%;
        height: 900px;
    }
    .third .settings {
        width: 100%;
        height: 1120px;
    }
    .images,.third .images {
        float: left;
        margin-top: 10px;
        width: 100%;
    }
    .information h3 {
        width: 100%;
    }.information span {
        width: 90%;
    }
    .upload-demo {
        margin-left: 1%;
    }
    .quantity-buttons {
        width: 90%;
    }
    .seed button {
        margin-right: 0px;
    }

    .fourth .settings {
        height: 1180px;
        width: 100%;
    }
    .fourth .images {
        margin-top: 10px;
        float: left;
        width: 100%;
    }
    .fourth .seed button {
        margin-top: 35px;
        float: left;
    }

}
</style>