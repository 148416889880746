<template>
  <div>
    <h2>当前使用的key：{{now_openai_key}}</h2>
    当前key列表：
    <div v-for="(i,index) in keys" :key="index">
      id: {{index}}   {{ i }}
    </div>
    <el-button type="primary" @click="change_key">添加key</el-button>
    <el-button type="primary" @click="remove">减少key</el-button>
    <el-button type="primary" @click="set">设置当前key</el-button>
    <br>
    添加提示词：
    <el-input v-model="prompt_name" placeholder="请输入内容">提示词模组简称</el-input>
    <el-input v-model="prompt_content" placeholder="请输入内容">提示词内容</el-input>
    <el-button type="primary" @click="add_prompt">添加提示词</el-button>
  </div>
</template>

<script>
const axios = require('axios');
import{ GPTAPI} from "@/config";
const token = localStorage.getItem('token')
                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': ` ${token}`,
                        'X-Requested-With': 'XMLHttpRequest'
                    }
                };
export default {
  data() {
    return {
      now_openai_key: '',
      keys: [],
      prompt_name: '',
      prompt_content: '',
    };
  },
  methods: {
    change_key(){
                const key = prompt("添加key");
                if (key === null || key === "") {
                     this.$message({
                    message: '输入无效',
                    type: 'error'
                });
                    return;
                }
                const data =  {
                    key:key
                }
                axios.post(GPTAPI + '/update_key', data, config)
                    .then(response => {
                        if (response.data.code == 200){
                            alert("修改成功")
                        }else{
                            alert("修改失败")
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            remove(){
                const index = prompt("减少key");
                if (index === null || index === "") {
                     this.$message({
                    message: '输入无效',
                    type: 'error'
                });
                    return;
                }
                const data =  {
                    index:index
                }
                axios.post(GPTAPI + '/remove', data, config)
                    .then(response => {
                        if (response.data.code == 200){
                            alert("修改成功")
                        }else{
                            alert("修改失败")
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            set(){
                const index = prompt("设置当前key(输入key的id)");
                if (index === null || index === "") {
                     this.$message({
                    message: '输入无效',
                    type: 'error'
                });
                    return;
                }
                const data =  {
                    index:index
                }
                axios.post(GPTAPI + '/set', data, config)
                    .then(response => {
                        if (response.data.code == 200){
                            alert("修改成功")
                        }else{
                            alert("修改失败")
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
            },
            add_prompt(){
                const data =  {
                    prompt_name: this.prompt_name,
                    prompt_content: this.prompt_content,
                }
                axios.post(GPTAPI + '/add_prompt', data, config)
                    .then(response => {
                        if (response.data.code == 200){
                            alert("添加成功")
                        }else{
                            alert("添加失败")
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
  },
  created() {
                const data = {
                }
                axios.post(GPTAPI + '/get_user_list', data, config)
                    .then(response => {
                        this.userlist = response.data["user_list"]
                        // console.log( response.data["user_list"])
                        this.now_openai_key = response.data["now_openai_key"]
                        this.keys = response.data["keys"]
                        // console.log(response.data)
                    })
                    .catch(error => {
                        console.log(error);
                    })
        },
};
</script>
<style scoped>
* {
  font-size: 16px;
}
</style>