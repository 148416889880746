import { render, staticRenderFns } from "./SWOT.vue?vue&type=template&id=6f9bbf6b&scoped=true&"
import script from "./SWOT.vue?vue&type=script&lang=js&"
export * from "./SWOT.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f9bbf6b",
  null
  
)

export default component.exports