<template>
    <div class="ArticleSummary">
        <hello 
        :inputContent="inputContent"
        :title="title"
        :title02="title02"
        :systemPrompt="systemPrompt"
        :name_model="name_model"
        @input-change="handleInputChange"
        ></hello>
    </div>
</template>
<script>
import hello from "@/components/tool/helloWorld.vue";
export default {
    components:{
            hello
        },
data() {
    return {
        input: '',
        title: "微信文章总结",
        name_model: "微信文章总结",
        title02:"帮你总结概括微信文章内容，直接粘贴链接到输入框，无需输入额外文字。",
        inputContent: "示例：国庆节假期七天长,应到户外去逛一逛;调整心态快乐无束,大自然中感受清风;吃吃美食来个几盅,快乐国庆无比轻松",
        systemPrompt:"ArticleSummary"  //对话模组
    };
},
methods: {
    handleInputChange(val) {
    this.input = val;
    alert(this.input)
    }
}


};
</script>

<style scoped>
</style>